<div id="header" class="content-heading empty-list-heading">
  <p class="heading-text" *ngIf="!isUpdate">
    <span>{{ 'ACCOUNT.VENUES.CREATE.CREATE_HEADER' | translate: { childrenName: accountChildrenNameSingular } }}</span>
    </p>
  <p class="heading-text" *ngIf="isUpdate">{{ 'SHARED.SYSTEM.VENUE_DETAILS' | translate: { childrenName: accountChildrenNameSingular } }}</p>
</div>

<layout-content>
  <div class="row">
    <form [formGroup]="venueForm" (ngSubmit)="onSubmit(venueForm)" class="AddVenueForm">
      <!-- KEY INFO SECTION -->
      <div class="col-sm-12 col-md-6 VenueDetails" formGroupName="details">
        <h4 class="page-header mt0">{{ 'SHARED.SYSTEM.KEY_INFO' | translate }}</h4>
        <mat-form-field>
          <mat-label>{{ 'SHARED.SYSTEM.LEGAL_COMPANY_NAME' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            list="autocompleteOff"
            required
            [placeholder]="'SHARED.SYSTEM.STREET_ADDRESS_INPUT_PLACEHOLDER' | translate"
            name="address"
            formControlName="legalName" />
          <mat-error appErrors="details.legalName" [errors]="errors.general"> </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'SHARED.SYSTEM.BRAND_NAME' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            list="autocompleteOff"
            required
            [placeholder]="'VENUE.SETTINGS.DETAILS.PLACEHOLDERS.VENUE_NAME_INPUT' | translate"
            formControlName="brandName" />
          <mat-error appErrors="details.brandName" [errors]="errors.general"> </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'VENUE.SETTINGS.DETAILS.LABELS.ORGANISATION_NUMBER' | translate }}</mat-label>
          <input matInput autocomplete="off" list="autocompleteOff" [placeholder]="getPhonePlaceholder()" formControlName="number" />
          <mat-error appErrors="details.number" [errors]="errors.number.concat(errors.general)"> </mat-error>
        </mat-form-field>
        <ng-container *ngIf="(getAccount$() | async)?.hasAccessTo().groups && isUpdate">
          <label class="fb-field">{{ 'SHARED.SYSTEM.GROUPS' | translate }}</label>
          <app-multi-select-tag
            [tags]="venueGroupsDropdownTags"
            [readonlyMode]="true"
            [placeholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.PLACEHOLDER' | translate"
            [noTagsPlaceholder]="'ACCOUNT.GROUPS.LISTS.VENUES.MULTI_SELECT_TAG.NO_TAGS_PLACEHOLDER' | translate">
          </app-multi-select-tag>
          <div class="mt-sm">
            <small>
              {{ 'VENUE.SETTINGS.DETAILS.GROUPS.MANAGEMENT_INFO' | translate }}
              <a *ngIf="isFoodbackAdmin; else regularUser" (click)="redirectToGroups()" class="link fb-link">{{
                'VENUE.SETTINGS.DETAILS.GROUPS.ACCOUNT_SETTINGS' | translate
              }}</a>

              <ng-template #regularUser> {{ 'VENUE.SETTINGS.DETAILS.GROUPS.ACCOUNT_SETTINGS' | translate }} </ng-template>
            </small>
          </div>
        </ng-container>
      </div>
      <!-- /KEY INFO SECTION -->
      <div class="col-sm-12 col-md-6 mb-lg">
        <!-- LOCATION SECTION -->
        <div class="LocationDetails" formGroupName="location">
          <h4 class="page-header mt0">{{ 'VENUE.SETTINGS.DETAILS.HEADERS.HEADER2' | translate }}</h4>
          <app-select
            *ngIf="countries"
            [items]="countries"
            [label]="'SHARED.SYSTEM.COUNTRY' | translate"
            [formControl]="countryCtrl"
            [required]="true"
            ngDefaultControl>
          </app-select>
          <app-select
            [items]="timeZones"
            [required]="true"
            [label]="'ADMIN.ACCOUNTS.FORM.TIME_ZONE' | translate"
            [formControl]="timeZoneCtrl"
            ngDefaultControl>
          </app-select>
          <mat-form-field>
            <mat-label>{{ 'SHARED.SYSTEM.STREET_ADDRESS' | translate }}</mat-label>
            <input
              matInput
              autocomplete="off"
              list="autocompleteOff"
              [placeholder]="'SHARED.SYSTEM.STREET_ADDRESS_INPUT_PLACEHOLDER' | translate"
              name="address"
              formControlName="address" />
            <mat-error appErrors="location.address" [errors]="errors.address"> </mat-error>
          </mat-form-field>
          <div class="fb-address-container">
            <div class="mr no-padding sm-expander">
              <mat-form-field>
                <mat-label>{{ 'SHARED.SYSTEM.ZIP_CODE' | translate }}</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  list="autocompleteOff"
                  [placeholder]="'SHARED.SYSTEM.ZIP_CODE' | translate"
                  name="code"
                  formControlName="code" />
                <mat-error appErrors="location.code" [errors]="errors.code"> </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-9 no-padding lg-expander">
              <mat-form-field>
                <mat-label>{{ 'SHARED.SYSTEM.CITY' | translate }}</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  list="autocompleteOff"
                  [placeholder]="getCityPlaceholder()"
                  name="city"
                  formControlName="city" />
                <mat-error appErrors="location.city" [errors]="errors.city"> </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- /LOCATION SECTION -->
        <!--  BUSINESS HOURS SECTION-->
        <div formGroupName="businessHours" *ngIf="venueForm.controls.businessHours">
          <h4 class="page-header mt-lg">{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.HEADER' | translate }}</h4>
          <p>{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.MAIN_DESC' | translate }}</p>
          <app-info-form-box *ngIf="isTimeOverlapping" [status]="infoBoxStatus">{{
            'SHARED.VALIDATION_MSG.IMAGES.DAYS_HOURS_OVERLAPPING' | translate
          }}</app-info-form-box>
          <div class="AddVenueForm-businessHours">
            <ng-container formArrayName="daysHours">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="20px"
                *ngFor="let control of venueForm.controls.businessHours['controls'].daysHours['controls']; let i = index">
                <p>
                  <b>{{ weekDayNames[i] }}</b>
                </p>
                <ng-container [formGroupName]="i">
                  <app-select
                    class="mr-lg AddVenueForm-controlSmall"
                    formControlName="activeFrom"
                    [items]="hoursArray"
                    [label]="'SHARED.SYSTEM.OPEN_FROM' | translate"
                    [required]="true"
                    [isSearchModeEnable]="false"
                    ngDefaultControl>
                  </app-select>
                  <app-select
                    class="mr-lg AddVenueForm-controlSmall"
                    formControlName="activeTo"
                    [items]="hoursArray"
                    [label]="'SHARED.SYSTEM.OPEN_TO' | translate"
                    [required]="true"
                    [isSearchModeEnable]="false"
                    ngDefaultControl>
                  </app-select>
                  <div>
                    <span *ngIf="i === 0">{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.CLOSED' | translate | uppercase }}</span>
                    <label class="checkbox c-checkbox">
                      <input type="checkbox" formControlName="isClosed" (change)="isClosedChanged(i)" name="isClosed" />
                      <span class="fa fa-check fb-checkbox"></span>
                    </label>
                  </div>
                </ng-container>
                <a *ngIf="i === 0" (click)="copyValuesToRest()">{{
                  'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.COPY_TO_ALL_DAYS' | translate
                }}</a>
              </div>
            </ng-container>
            <span class="AddVenueForm-smallHeader">{{
              'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.FEEDBACK_HOURS' | translate | uppercase
            }}</span>
            <p>{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.FEEDBACK_HOURS_DESC' | translate }}</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <label class="radio c-radio">
                <input type="radio" name="openingMode" formControlName="openingMode" [value]="businessHoursType.ALWAYS_OPEN" />
                <span class="fa fa-circle"></span>
                <span>{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.ALWAYS_ON' | translate }}</span>
              </label>
              <label class="radio c-radio">
                <input type="radio" name="openingMode" formControlName="openingMode" [value]="businessHoursType.SAME_OPENING_HOURS" />
                <span class="fa fa-circle"></span>
                <span>{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.SAME_HOURS' | translate }}</span>
              </label>
              <label class="radio c-radio">
                <input type="radio" name="openingMode" formControlName="openingMode" [value]="businessHoursType.CLOSING" />
                <span class="fa fa-circle"></span>
                <span>{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.CLOSING' | translate }}</span>
              </label>
              <span class="mr-lg">+</span>
              <app-select
                class="mr-lg AddVenueForm-controlAdditionalHours"
                formControlName="closingAdditionHours"
                [items]="additionalHours"
                [label]="null"
                [isSearchModeEnable]="false"
                ngDefaultControl>
              </app-select>
              <span>{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.HOURS' | translate }}</span>
            </div>
            <label class="checkbox c-checkbox">
              <input type="checkbox" formControlName="isPortalClosed" name="askClosePortal" />
              <span class="fa fa-check fb-checkbox"></span>
              {{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.BUSINESS_HOURS.ALSO_CLOSE_PORTAL' | translate }}
            </label>
          </div>
        </div>
        <!--ADMIN INTERNAL COMMENT-->
        <div class="mb-lg" formGroupName="details" *ngIf="isFoodbackAdmin">
          <h4 class="page-header mt-lg">{{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.FOODBACK_ADMIN.HEADER' | translate }}</h4>
          <label class="fb-required-field">{{ 'Quarantine Time' | translate }}</label>
          <div class="row-eq-height">
            <div class="VenueDetails-hoursInput p0 mr">
              <mat-form-field>
                <input matInput autocomplete="off" list="autocompleteOff" placeholder="" type="number" min="0"
                  formControlName="quarantineDuration"/>
              </mat-form-field>
            </div>
            <span>{{ 'SHARED.SYSTEM.HOURS' | translate }}</span>
          </div>
          <label class="fb-required-field">{{ 'ADMIN.ACCOUNTS.FORM.SEGMENT_DESCRIPTION.LOCATION' | translate }}</label>
          <div>
            <app-dropdown
              placeholder="Location"
              (onSelect)="onSegmentLocationSelect($event)"
              [items]="emplacements"
              [required]="true"
              [activeItems]="[activeVenueLocation]"></app-dropdown>
          </div>
          <mat-form-field class="TextareaInput">
            <mat-label> {{ 'ACCOUNT.DETAILS.EMAIL_REPORTS.FOODBACK_ADMIN.INTERNAL_COMMENT_HEADER' | translate }} </mat-label>
            <textarea matInput autocomplete="off" class="AddVenueForm-internalComment" formControlName="internalComment"> </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="submit-panel-spacer"></div>
        <div class="submit-panel">
          <button class="btn btn-foodback btn-foodback--secondary" type="button" (click)="discardChanges()">
            {{ 'SHARED.SYSTEM.CANCEL' | translate }}
          </button>
          <button class="btn btn-foodback text-uppercase" [disabled]="disabledVal">
            <span *ngIf="isUpdate; else buttonForCreate"> {{ 'SHARED.SYSTEM.SAVE_CHANGES' | translate }} </span>
            <ng-template #buttonForCreate>
               <span>{{ 'SHARED.VENUES.ADD_VENUE' | translate: { childrenName: accountChildrenNameSingular} }}</span>
            </ng-template>
          </button>
          <button
            *ngIf="isFoodbackAdmin && isSingleVenueAccount"
            (click)="switchToMultipleRestaurant()"
            class="btn btn-foodback"
            type="button">
            <span>{{ 'VENUE.SETTINGS.DETAILS.BUTTONS.SWITCH_TO_MULTIPLE_RESTAURANT' | translatetranslate: { childrenName: accountChildrenNameSingular} }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</layout-content>
