import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CONSTANTS } from './constants';
import { Dictionary, InputTextMask } from './interfaces/common.interface';
import { IFromTimingRawValue } from './interfaces/form.interface';

// eslint-disable-next-line
export const EMAIL_REG_EX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line max-len
const URL_REG_EX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export class CustomValidators {
  // allows email value to be empty while retaining the ability to verify its correctness if non-empty.
  // https://github.com/angular/angular/issues/16183
  static optionalEmail(control: AbstractControl): Dictionary<any> {
    const emailError = Validators.email(control);

    if (control.value && emailError) {
      return { email: {} };
    }

    return null;
  }

  static zipCode(zipMask: InputTextMask): (control: AbstractControl) => Dictionary<any> {
    return CustomValidators.maskValidator(zipMask, 'zip');
  }

  static maskValidator(mask: InputTextMask, propertyName: string): (control: AbstractControl) => Dictionary<any> {
    if (typeof mask === 'boolean') {
      return () => ({});
    }

    return (control: AbstractControl) => {
      const { value }: { value: string } = control;

      if (!value) {
        return {};
      }
      const isValueCorrect = mask.every((maskElement, index) => {
        if (typeof maskElement === 'string') {
          return value.charAt(index) === maskElement;
        }

        return maskElement.test(value.charAt(index));
      });

      return isValueCorrect ? {} : { [propertyName]: {} };
    };
  }

  static barCode(control: AbstractControl): Dictionary<any> {
    if (!control.value) {
      return { barCode: {} };
    }
    const value = control.value.toString();
    const barCodeLength = 13;

    if (value.length !== barCodeLength) {
      return { barCode: {} };
    }
    const checkSum = value.split('').reduce(
      (p: any, v: any, i: any) =>
        // eslint-disable-next-line no-magic-numbers
        i % 2 === 0 ? p + 1 * v : p + 3 * v,
      0
    );

    // eslint-disable-next-line no-magic-numbers
    if (checkSum % 10 !== 0) {
      return { barCode: {} };
    }

    return null;
  }

  static isOnlyDigits(control: AbstractControl): Dictionary<any> {
    if (control.value === null) {
      return null;
    }
    if (isNaN(control.value)) {
      return { notDigits: {} };
    }

    return null;
  }

  static email(control: AbstractControl): Dictionary<any> {
    const email = control.value;

    if (EMAIL_REG_EX.test(email)) {
      return null;
    }

    return { email: true };
  }

  static url(control: AbstractControl): Dictionary<any> {
    const url = control.value;

    if (URL_REG_EX.test(url)) {
      return null;
    }

    return { url: true };
  }

  static noWhitespaceValidator(control: AbstractControl): Dictionary<any> {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  }

  static zipCodeMaxLength(control: AbstractControl): Dictionary<any> {
    if (control.value && control.value.length > CONSTANTS.VALIDATION.MAX_CODE_LENGTH) {
      return { zipCodeMaxLength: {} };
    }

    return null;
  }

  static dateRange(control: AbstractControl): Dictionary<any> {
    const dateFromControl: AbstractControl = (control as FormGroup).controls.dateFrom;
    const dateToControl: AbstractControl = (control as FormGroup).controls.dateTo;

    if (!dateFromControl.value && !dateToControl.value) {
      return { dateRange: true };
    }

    return null;
  }

  static dateRangeValue(control: AbstractControl): Dictionary<any> {
    const dateFromControl: AbstractControl = (control as FormGroup).controls.dateFrom;
    const dateToControl: AbstractControl = (control as FormGroup).controls.dateTo;
    const dateFromValue: moment.Moment = moment(dateFromControl.value);
    const dateToValue: moment.Moment = moment(dateToControl.value);

    if (dateFromControl.value && dateToControl.value && dateToValue.diff(dateFromValue) < 0) {
      return { dateRangeValue: true };
    }

    return null;
  }

  static valueNotFromPast(control: AbstractControl): Dictionary<any> {
    if (!control.value) {
      return null;
    }
  
    const inputDate = new Date(control.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (isNaN(inputDate.getTime())) {
      return { invalidDate: true };
    }
  
    if (inputDate < today) {
      return { notPastDate: true };
    }
  
    return null;
  }

  static timing(control: AbstractControl): Dictionary<any> {
    const value: IFromTimingRawValue = control.value;

    if (!value.startDate || !value.endDate) {
      return null;
    }
    const startTime: string = value.startTime ? value.startTime.id : '00:00:00';
    const endTime: string = value.endTime ? value.endTime.id : '23:59:59';

    value.startDate.set({
      hour: +startTime.split(':')[0],
      minute: +startTime.split(':')[1],
    });
    value.endDate.set({
      hour: +endTime.split(':')[0],
      minute: +endTime.split(':')[1],
    });

    if (value.startDate.diff(value.endDate, 'minutes') >= 0) {
      return { timing: true };
    }

    return null;
  }
}
