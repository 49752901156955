import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subscription, throwError as observableThrowError, of } from 'rxjs';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { find } from 'lodash';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { AccountSingleVenueBackendService } from '../../../core/providers/backend/account-single-venue-backend.service';
import { CONSTANTS } from '../../constants';
import { CommonService } from '../../../core/providers/common/common.service';
import { ContextService } from '../../../core/providers/context/context.service';
import { CustomValidators } from '../../custom-validators';
import { DropdownUtil } from '../../../core/utils/dropdown-util';
import { EditUtilService, FormsUtil } from '../../../core/utils';
import { FormHelper } from './form-helper.interface';
import { FormModels } from '../../form-models';
import { I18nBackendService } from '../../../core/providers/backend/i18n-backend.service';
import { I18nCountriesAndTimeZonesDropdown, I18nTimeZonesByCountry } from '../../interfaces/i18n.interface';
import {
  IBusinessDayForBackend,
  IVenueFormBusinessHour,
  VenueForm,
  VenueFromBackend
} from '../../form-models-interfaces';
import { IDropdownItem } from '../../interfaces/dropdown/dropdown-item.interface';
import { InfoBoxStatus } from '../info-form-box/info-box.enum';
import { NavigationService } from '../../../core/providers/navigation/navigation.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { RolesService } from '../../../core/providers/backend/roles.service';
import { UsersBackendService } from '../../../core/providers/backend/users-backend.service';
import { UtilsService } from '../../../core/utils/utils.service';
import { ApiResponse } from '../../interfaces/api-response.interface';
import { ContextObject } from '../../interfaces/login-object';
import { VenueUser } from '../../interfaces/venue-user.interface';
import { BusinessHoursType } from './business-hours-types.enum';
import { VenueTypesBackendService } from 'app/core/providers/backend/venue-types-backend.service';

const INTERVAL_30 = 30;

@Component({
  selector: 'app-ven-add',
  templateUrl: './ven-add.component.html',
  styleUrls: ['./ven-add.component.scss'],
})
export class VenAddComponent implements OnInit, OnDestroy {
  // if edit mode
  venue: VenueFromBackend;
  venueUser: VenueUser;
  isVenueAdmin: boolean;

  isSingleVenueAccount = false;
  countries: IDropdownItem[];
  timeZones: IDropdownItem[];
  disabledVal = false;
  isFoodbackAdmin = false;
  errors = FormModels.VenueAccount.VenueErrors;
  accountUuid$: ReplaySubject<string> = new ReplaySubject();
  formHelpers: FormHelper = {
    accountUuid: '',
    venueUuid: '',
    employeesArr: [],
    manager: '',
    usedCity: '',
    usedCode: '',
    usedType: 'Restaurant',
    venueTypes: ['Restaurant'],
    internalComment: '',
    quarantineDuration: 0,
  };
  isUpdate = false;
  isLocationRequired = false; // because Angular has no API to check if a field is required or not
  hoursArray: IDropdownItem[] = this.commonService.createArrayOfSelects(INTERVAL_30);
  venueForm: FormGroup = this.formBuilder.group({
    details: this.formBuilder.group(new FormModels.VenueAccount.VenueDetails()),
    location: this.formBuilder.group(new FormModels.VenueAccount.VenueLocation()),
    businessHours: this.getBusinessHoursForm(),
    emplacementUuid: ''
  });
  venueGroupsDropdownTags: IDropdownItem[] = [];
  countryCtrl: FormControl = new FormControl();
  timeZoneCtrl: FormControl = new FormControl();
  weekDayNames: string[] = Object.values(this.translateService.instant('SHARED.COMPONENTS.DATE_PICKER.TIME.DAY'));
  businessHoursType: typeof BusinessHoursType = BusinessHoursType;
  additionalHours: IDropdownItem[] = Array.from({length: 3}, (_, i) => ({
    id: (i + 1).toString(),
    text: (i + 1).toString(),
  }));
  infoBoxStatus = InfoBoxStatus.ALERT;
  isTimeOverlapping = false;
  emplacements: IDropdownItem[] = [];
  activeVenueLocation: IDropdownItem | any;
  accountChildrenNameSingular: string;
  accountChildrenNamePlural: string;
  account: any;
  private subscriptions: Subscription = new Subscription(null);
  private timeZonesByCountry: I18nTimeZonesByCountry[];

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private formUtil: FormsUtil,
    private usersBackendService: UsersBackendService,
    private editUtilService: EditUtilService,
    private notificationService: NotificationService,
    private contextService: ContextService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private i18nBackendService: I18nBackendService,
    private accountSingleRestaurantBackendService: AccountSingleVenueBackendService,
    private rolesService: RolesService,
    private utilsService: UtilsService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private venueTypesBackendService: VenueTypesBackendService,
  ) {
  }

  redirectToGroups() {
    this.navigationService.goToAccountGroups(this.venue.accountUuid);
  }

  discardChanges() {
    if (this.isUpdate) {
      this.editUtilService.setChosenVenue(null);
      this.navigationService.goToVenueMainScreen(this.formHelpers.venueUuid);
    } else {
      this.cancelPopUp();
    }

    this.isUpdate = false;
  }

  onSubmit({valid}: { valid: boolean }) {
    this.venueForm.markAllAsTouched()
    if (valid) {
      const value = this.venueForm.getRawValue();

      this.disabledVal = true;
      value.details.type = this.formHelpers.usedType;

      this.manageValidForm(value);
    }
  }

  onSegmentLocationSelect(segmentLocation: IDropdownItem) {
    this.venueForm.get('emplacementUuid').setValue(segmentLocation?.id);
    this.activeVenueLocation = segmentLocation;
    // this.activeVenueLocation.id = segmentLocation.id;
    this.disabledVal = false;
  }

  switchToMultipleRestaurant() {
    this.notificationService
      .displayConfirmationPopupWithTranslationKey('VENUE.SETTINGS.DETAILS.MODALS.SWITCH_TO_MULTIPLE_RESTAURANT.CONFIRM')
      .pipe(mergeMap(() => this.makeSwitchRestaurantRequest$()))
      .subscribe(() => {
        this.notificationService.successWithTranslationKey('VENUE.SETTINGS.DETAILS.MODALS.SWITCH_TO_MULTIPLE_RESTAURANT.SUCCESS');
        this.accountSingleRestaurantBackendService.setSingleVenueMode(false);
        this.contextService.refreshContext$().subscribe();
      });
  }

  getPhonePlaceholder(): string {
    const country = this.venueForm.get('location.country').value;

    return this.formUtil.getPhonePlaceholder(country);
  }

  getCityPlaceholder(): string {
    const country = this.venueForm.get('location.country').value;

    return this.formUtil.getCityPlaceholder(country);
  }

  ngOnInit() {
    this.subscriptions.add(
      this.countryCtrl.valueChanges.subscribe(value => {
        if (value) {
          this.updateSelectedCountry(value);
        }
      })
    );
    this.subscriptions.add(
      this.timeZoneCtrl.valueChanges.subscribe(value => {
        if (value) {
          this.updateSelectedTimeZone(value);
        }
      })
    );
    this.subscriptions.add(
      this.venueForm.get('businessHours.openingMode').valueChanges.subscribe((value: BusinessHoursType) => {
        if (value === BusinessHoursType.CLOSING) {
          this.venueForm.get('businessHours.closingAdditionHours').enable({emitEvent: false});
        } else {
          this.venueForm.get('businessHours.closingAdditionHours').disable({emitEvent: false});
        }
      })
    );
    this.fetchAvailableCountries();
    // CREATE is in ACCOUNT CONTEXT, EDIT is in Venue CONTEXT
    this.contextService
      .getContext$()
      .pipe(first())
      .subscribe((contextObject: ContextObject) => {
        this.isUpdate = contextObject.type === CONSTANTS.CONTEXT.VENUE;
        this.isVenueAdmin = contextObject.isUserVenueAdmin;

        // this.countryCtrl.disable();
        if (this.isUpdate) {
          this.getDataForForm(contextObject.uuid);
          // this.timeZoneCtrl.disable();
        } else {
          this.formHelpers.accountUuid = contextObject.uuid;
          this.setDefaultCountryAndCompanyName();
        }
        this.accountChildrenNameSingular = this.contextService.getAccountChildrenNameTranslation(true);
        this.accountChildrenNamePlural = this.contextService.getAccountChildrenNameTranslation(false);
      });
    this.getSingleVenueModeState$().subscribe((isSingleVenueAccount: boolean) => {
      this.isSingleVenueAccount = isSingleVenueAccount;
    });
    this.isFoodbackAdmin = this.rolesService.isAdmin();

    this.venueTypesBackendService
    .getAccountLocationNames()
    .subscribe(response => {
      if (response && response.length > 0) {
        this.emplacements = response.map(venueTypeMethod => ({
          isSelected: false,
          id: venueTypeMethod.uuid,
          text: venueTypeMethod.name,
          ...venueTypeMethod,
        }));
      }
      if (this.venue && this.venue.emplacement) {
        this.activeVenueLocation = find(this.emplacements, { id: this.venue.emplacement?.uuid });
      } else if (this.account) {
        this.activeVenueLocation = find(this.emplacements, { id: this.account.emplacement?.uuid });
      }else {
        this.activeVenueLocation = this.emplacements[0];
      }
      if (this.activeVenueLocation) {
        this.onSegmentLocationSelect(this.activeVenueLocation);
      }
    })
  }

  getAccount$(): Observable<FoodbackAccount> {
    return this.accountService.getAccount$();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isClosedChanged(index: number): void {
    const daysHours: FormArray = (this.venueForm.controls.businessHours as FormGroup).controls.daysHours as FormArray;
    const form: FormGroup = daysHours.controls[index] as FormGroup;

    if (form.controls.isClosed.value) {
      form.controls.activeFrom.disable({emitEvent: false});
      form.controls.activeTo.disable({emitEvent: false});
    } else {
      form.controls.activeFrom.enable({emitEvent: false});
      form.controls.activeTo.enable({emitEvent: false});
    }
  }

  copyValuesToRest(): void {
    const numberOfDays = 7;
    const firstValue: IVenueFormBusinessHour = this.venueForm.get('businessHours.daysHours.0').value;

    for (let i = 1; i < numberOfDays; i++) {
      if (firstValue.isClosed) {
        this.venueForm.get(`businessHours.daysHours.${i}`).setValue({
          ...this.getDefaultHours(),
          isClosed: firstValue.isClosed,
        });
      } else {
        this.venueForm.get(`businessHours.daysHours.${i}`).setValue(firstValue);
      }

      this.isClosedChanged(i);
    }
  }

  private setCountrySelectValue() {
    const selectedCountry = this.venueForm.get('location.country').value;
    const selectedTimezone = this.venueForm.get('location.timezone').value;

    if (this.countries && this.countries.length > 0 && selectedCountry !== '') {
      this.countryCtrl.setValue(this.countries.find(country => country.id === selectedCountry));
      this.setTimeZone(this.countryCtrl.value);
      this.timeZoneCtrl.setValue(this.timeZones.find(timezone => timezone.id === selectedTimezone));
    }
  }

  private setTimeZone(selectedCountry: IDropdownItem) {
    this.timeZones = this.timeZonesByCountry.find(timeZonesByCountry => timeZonesByCountry.code === selectedCountry.id).availableTimeZones;
    this.timeZoneCtrl.setValue(this.timeZones[0]);
  }

  private updateSelectedCountry(selectedCountry: IDropdownItem) {
    if (selectedCountry.id === this.venueForm.get('location.country').value) {
      return;
    }

    this.setTimeZone(selectedCountry);
    this.venueForm.get('location').markAsPristine();
    this.venueForm.get('location').setValue({
      address: null,
      code: null,
      city: null,
      country: selectedCountry.id,
      timezone: this.timeZoneCtrl.value.id,
    });
  }

  private updateSelectedTimeZone(selectedTimeZone: IDropdownItem) {
    if (selectedTimeZone.value === this.venueForm.get('location.timezone').value) {
      return;
    }

    this.venueForm.get('location.timezone').setValue(selectedTimeZone.value);
  }

  private getSingleVenueModeState$(): Observable<boolean> {
    return this.accountSingleRestaurantBackendService.isSingleVenueMode();
  }

  private cancelPopUp() {
    this.notificationService
      .displayConfirmationPopupWithTranslationKey('ACCOUNT.VENUES.CREATE.CANCEL.ALERT')
      .subscribe((response: boolean) => {
        if (response) {
          this.goToList();
        }
      });
  }

  private manageValidForm(value: VenueForm) {
    value.uuid = this.isUpdate ? this.formHelpers.venueUuid : this.formHelpers.accountUuid;
    if (this.activeVenueLocation) {
      value.emplacementUuid = this.activeVenueLocation.id;
    } else {
      this.disabledVal = true;
      return;
    }
    const request$: Observable<ApiResponse> = this.isUpdate
      ? this.usersBackendService.updateVenue$(value)
      : this.usersBackendService.addVenue$(value);

    request$
      .pipe(
        catchError(error => {
          this.isTimeOverlapping = error.errorCode === CONSTANTS.VALIDATION_STATUS_ERROR.TIME_OVERLAPPING;

          return observableThrowError(null);
        }),
        mergeMap(() => (!this.isUpdate ? this.contextService.refreshContext$() : of(null)))
      )
      .subscribe(() => {
        this.isTimeOverlapping = false;

        if (this.isUpdate) {
          this.editUtilService.setChosenVenue(null);
          this.disabledVal = false;
          this.notificationService.success('ACCOUNT.SETTINGS.VENUES.EDIT_MESSAGE_SUCCESS');
          this.contextService.refreshContext$().subscribe();
        } else {
          this.goToList();
          const translation = this.translateService.instant('ACCOUNT.SETTINGS.VENUES.CREATE_MESSAGE_SUCCESS', {childrenName: this.accountChildrenNameSingular} );
          this.notificationService.success(translation);
        }
      });
  }

  private getDataForForm(venueUuid: string) {
    this.usersBackendService.getSingleVenue$(venueUuid).subscribe((response: VenueFromBackend) => {
      this.venue = response;
      this.accountUuid$.next(response.accountUuid);
      this.accountUuid$.complete();
      this.setDataToForm(response);
      this.venueGroupsDropdownTags = DropdownUtil.convertToDropdownItems(this.venue.venueGroups, ['uuid', 'name']);
    });
  }

  private goToList() {
    this.editUtilService.setChosenVenue(null);
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  private setDataToForm(chosenVenue: VenueFromBackend) {
    const formObj: VenueForm = {
      details: {
        legalName: chosenVenue.companyName,
        brandName: chosenVenue.brandName,
        number: chosenVenue.organizationNumber,
        type: 'Restaurant',
        manager: '',
        internalComment: chosenVenue.internalComment,
        quarantineDuration: chosenVenue.quarantineDuration,
      },
      location: {
        address: chosenVenue.location.street,
        city: chosenVenue.location.city,
        timezone: chosenVenue.location.timezone,
        code: chosenVenue.location.zipCode,
        country: chosenVenue.location.countryCode || CONSTANTS.COUNTRIES.ALFA3_CODES.NORWAY,
      },
      emplacementUuid: chosenVenue.emplacement ? chosenVenue.emplacement.uuid : null,
      businessHours: {
        daysHours: chosenVenue.openingHours.length === 0 ? this.getDefaultBusinessDays() : this.getBusinessDays(chosenVenue.openingHours),
        openingMode: this.getBusinessHoursType(chosenVenue.feedbackHours),
        closingAdditionHours: chosenVenue.feedbackHours
          ? this.additionalHours.find((item: IDropdownItem) => item.id === chosenVenue.feedbackHours.toString())
          : null,
        isPortalClosed: chosenVenue.isPortalClosedWhenVenueClosed,
      },
    };

    this.formHelpers.venueUuid = chosenVenue.uuid;
    this.venueForm.setValue(formObj);
    this.updateHoursStatuses();
    this.setCountrySelectValue();
  }

  private updateHoursStatuses(): void {
    const numberOfDays = 7;

    for (let i = 0; i < numberOfDays; i++) {
      this.isClosedChanged(i);
    }
  }

  private getBusinessDays(openingHours: IBusinessDayForBackend[]): IVenueFormBusinessHour[] {
    const numberOfDays = 7;
    const daysHours: IVenueFormBusinessHour[] = [];

    for (let i = 0; i < numberOfDays; i++) {
      const venueBusinessDay: IBusinessDayForBackend = openingHours.find((dayHour: IBusinessDayForBackend) => dayHour.day - 1 === i);

      if (venueBusinessDay) {
        daysHours.push({
          activeFrom: this.hoursArray.find((hour: IDropdownItem) => hour.id === venueBusinessDay.hours[0][0]),
          activeTo: this.hoursArray.find((hour: IDropdownItem) => hour.id === venueBusinessDay.hours[0][1]),
          isClosed: venueBusinessDay.isClosed,
        });
      } else {
        daysHours.push(this.getDefaultBusinessDay());
      }
    }

    return daysHours;
  }

  private getDefaultBusinessDays(): IVenueFormBusinessHour[] {
    const numberOfDays = 7;
    const daysHours: IVenueFormBusinessHour[] = [];

    for (let i = 0; i < numberOfDays; i++) {
      daysHours.push({...this.getDefaultBusinessDay(), isClosed: false});
    }

    return daysHours;
  }

  private getDefaultBusinessDay(): IVenueFormBusinessHour {
    const defaultHour: { activeFrom: IDropdownItem; activeTo: IDropdownItem } = this.getDefaultHours();

    return {
      activeFrom: defaultHour.activeFrom,
      activeTo: defaultHour.activeTo,
      isClosed: true,
    };
  }

  private getBusinessHoursType(feedbackHours: number): BusinessHoursType {
    if (feedbackHours === null) {
      return BusinessHoursType.ALWAYS_OPEN;
    }
    if (feedbackHours === 0) {
      return BusinessHoursType.SAME_OPENING_HOURS;
    }

    return BusinessHoursType.CLOSING;
  }

  private fetchAvailableCountries() {
    this.i18nBackendService.getCountriesWithTimeZones$().subscribe((countriesWithTimeZones: I18nCountriesAndTimeZonesDropdown) => {
      this.timeZonesByCountry = countriesWithTimeZones.timeZonesByCountry;
      this.countries = countriesWithTimeZones.countries;
      this.setCountrySelectValue();
    });
  }

  private makeSwitchRestaurantRequest$(): Observable<void> {
    return this.accountUuid$.pipe(
      mergeMap((accountUuid: string) => this.accountSingleRestaurantBackendService.changeSingleVenueStatus(accountUuid, false))
    );
  }

  private setDefaultCountryAndCompanyName(): void {
    this.getAccount$().subscribe((account: FoodbackAccount) => {
      this.account = account;
      this.venueForm.get('location.country').setValue(account.location.countryCode);
      this.venueForm.get('location.timezone').setValue(account.location.timezone);
      this.venueForm.get('details.legalName').setValue(account.companyName);
    });
  }

  private getBusinessHoursForm(): FormGroup {
    const numberOfDays = 7;
    const form: FormGroup = this.formBuilder.group({
      daysHours: this.formBuilder.array([]),
      openingMode: [null],
      closingAdditionHours: [null],
      isPortalClosed: [false],
    });

    for (let i = 0; i < numberOfDays; i++) {
      (form.controls.daysHours as FormArray).push(this.getBusinessDayForm());
    }

    return form;
  }

  private getBusinessDayForm(): FormGroup {
    const defaultHour: { activeFrom: IDropdownItem; activeTo: IDropdownItem } = this.getDefaultHours();

    return this.formBuilder.group({
      activeFrom: [defaultHour.activeFrom, [Validators.required]],
      activeTo: [defaultHour.activeTo, [Validators.required]],
      isClosed: [false],
    });
  }

  private getDefaultHours(): {
    activeFrom: IDropdownItem;
    activeTo: IDropdownItem;
  } {
    return {activeFrom: this.hoursArray[0], activeTo: this.hoursArray[0]};
  }
}
