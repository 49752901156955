import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { IFusionChartCategories, IFusionChartDataSet } from '../../../../../vendor/types/fusionCharts';
import { ChartUtilsService } from '../../../../core/utils';
import { CONSTANTS } from '../../../constants';
import { VenueCategoryScoresDistribution } from '../../../interfaces/analytics.interface';

@Component({
  selector: 'app-score-distribution',
  templateUrl: './score-distribution.component.html',
  styleUrls: ['./score-distribution.component.scss'],
})
export class ScoreDistributionComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line
  @Input()
  set data(categoryScoresDistribution: VenueCategoryScoresDistribution) {
    if (!categoryScoresDistribution) {
      return;
    }

    this.translateService.get('HOME.OVERVIEW.CATEGORY_DETAILS.SCORE_DISTRIBUTION').subscribe(({ NUMBER_OF_RATINGS }) => {
      this.initChart();
      this.chartCategories = [
        {
          category: categoryScoresDistribution.map(val => ({
            label: val.answer.toString(),
          })),
        },
      ];

      const total = categoryScoresDistribution.reduce((acc, val) => acc + (val.value || 0), 0);

      this.chartDataSet = [
        {
          seriesName: NUMBER_OF_RATINGS,
          renderAs: 'column2d',
          data: categoryScoresDistribution.map(value => {
            const percent = total > 0 ? (value.value / total) * 100 : 0;
            const percentRounded = Math.round(percent);
            return {
              value: value.value.toString(),
              color: '#999999',
              displayValue: `${percentRounded}%`,
              toolText: `${value.answer}: ${percentRounded}%`
            };
          }),
        },
      ];

      this.dataSource.categories = this.chartCategories;
      this.dataSource.dataset = this.chartDataSet;

      this.dataSource.chart = {
        ...this.dataSource.chart,
        plotSpacePercent: '60',
        showLegend: '0',
        showValues: '1',
        valueFontSize: '12',
        valueFontColor: '#333333',
        usePlotGradientColor: '0',
        useRoundEdges: '0'
      };
    });
  }

  chartCategories: IFusionChartCategories[];
  chartDataSet: IFusionChartDataSet[];
  dataSource;

  private onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService, private chartUtilsService: ChartUtilsService) { }

  ngOnInit() {
    this.initChart();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initChart() {
    const additionalOptions = {
      labelDisplay: 'auto',
      rotateLabels: '0',
      showYAxisValues: '0',
      showLegend: '0'
    };

    this.dataSource = this.chartUtilsService.initChartWithDataSetAndCategories(additionalOptions);
  }
}
