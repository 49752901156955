import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { SnackbarComponent } from '../../../shared/components/snackbar/snackbar.component';
import { CONSTANTS } from '../../../shared/constants';
import { UserForBackend } from '../../../shared/form-models-interfaces';
import {
  IRecipient,
  IRecipientsResponse,
  RecipientType,
} from '../../../shared/interfaces/notifications-backend.interface';

@Injectable()
export class NotificationService {
  private autoHideDuration = 3000;
  private isInstanceVisible = false;
  private msgQueue: MatSnackBarConfig[] = [];

  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  error(text: string) {
    const duration = 10000;

    this.addSnackBarMsg(text, 'error', duration);
  }

  errorWithTranslationKey(translationKey: string) {
    this.translateService.get(translationKey).subscribe(({ TEXT }) => {
      this.error(TEXT);
    });
  }

  info(text: string) {
    const duration = 5000;

    this.addSnackBarMsg(text, 'info', duration);
  }

  success(text: string) {
    const duration = 5000;

    this.addSnackBarMsg(
      this.translateService.instant(text),
      'success',
      duration
    );
  }

  successWithTranslationKey(translationKey: string, params?: any) {
    this.success(
      this.translateService.instant(`${translationKey}.TEXT`, params)
    );
  }

  warning(text: string) {
    this.addSnackBarMsg(text, 'warning');
  }

  fillAllFields() {
    this.translateService
      .get('SHARED.NOTIFICATIONS')
      .subscribe(({ FILL_ALL_FIELDS }) => {
        this.error(FILL_ALL_FIELDS);
      });
  }

  fillBarCode() {
    this.translateService
      .get('SHARED.NOTIFICATIONS')
      .subscribe(({ BAR_CODE_MISSING }) => {
        this.error(BAR_CODE_MISSING);
      });
  }

  successfullyChanged() {
    this.success('SHARED.NOTIFICATIONS.EDIT_RESULT_SUCCESS.TEXT');
  }

  displaySwalPopup(
    TITLE: string,
    TEXT: string,
    icon: SweetAlertIcon = 'success'
  ) {
    swal.fire(TITLE, TEXT, icon);
  }

  displaySwalPopupWithSpinner(
    title: string,
    text: string,
    icon: SweetAlertIcon = 'success'
  ) {
    swal.fire({
      title,
      text,
      icon,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    swal.showLoading(null);
  }

  hideLoadingPopup(): void {
    swal.close();
  }

  displayPopupWithTranslationKey(
    translationKey: string,
    icon: SweetAlertIcon = 'success'
  ): Observable<any> {
    return this.translateService
      .get(translationKey)
      .pipe(mergeMap(({ TITLE, TEXT }) => swal.fire(TITLE, TEXT, icon)))
      .pipe(map((response: SweetAlertResult) => response.value));
  }

  displayConfirmationPopup(
    TITLE: string,
    TEXT: string,
    BUTTONS: any,
    alertType: SweetAlertIcon = 'warning'
  ): Observable<boolean> {
    return from(
      swal.fire({
        title: TITLE,
        text: TEXT,
        icon: alertType,
        showCancelButton: true,
        confirmButtonText: BUTTONS.OK,
        confirmButtonColor: CONSTANTS.FOODBACK.MAIN_COLOR,
        cancelButtonText: BUTTONS.CANCEL ? BUTTONS.CANCEL : null,
      })
    ).pipe(
      map((response: SweetAlertResult) => this.getConfirmationPopup(response))
    );
  }

  displayConfirmationSuccessPopup(
    TITLE: string,
    TEXT: string,
    BUTTONS: any,
  ): Observable<boolean> {
    return from(
      swal.fire({
        title: TITLE,
        text: TEXT,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: BUTTONS.OK,
        confirmButtonColor: CONSTANTS.FOODBACK.MAIN_COLOR,
      })
    ).pipe(
      map((response: SweetAlertResult) => this.getConfirmationPopup(response))
    );
  }

  displayConfirmationPopupWithTranslationKey(
    translationKey: string,
    alertType: SweetAlertIcon = 'warning'
  ): Observable<boolean> {
    return this.translateService
      .get(translationKey)
      .pipe(
        mergeMap(({ TITLE, TEXT, BUTTONS }) =>
          swal.fire({
            title: TITLE,
            text: TEXT,
            icon: alertType,
            showCancelButton: true,
            confirmButtonText: BUTTONS.OK,
            confirmButtonColor: CONSTANTS.FOODBACK.MAIN_COLOR,
            cancelButtonText: BUTTONS.CANCEL,
            allowOutsideClick: false
          })
        )
      )
      .pipe(
        map((response: SweetAlertResult) => this.getConfirmationPopup(response))
      );
  }

  addSnackBarMsg(
    message: string,
    status: 'error' | 'info' | 'success' | 'warning',
    duration: number = this.autoHideDuration
  ) {
    const config = new MatSnackBarConfig();

    config.data = {
      message,
    };
    config.panelClass = `SnackBar-${status}`;
    config.duration = duration;
    config.verticalPosition = 'top';
    this.msgQueue.push(config);

    if (!this.isInstanceVisible) {
      this.showSnackBar();
    }
  }

  showSnackBar() {
    if (this.msgQueue.length === 0) {
      return;
    }
    const config = this.msgQueue.shift();

    this.isInstanceVisible = true;
    this.snackBar
      .openFromComponent(SnackbarComponent, config)
      .afterDismissed()
      .subscribe(() => {
        this.isInstanceVisible = false;
        this.showSnackBar();
      });
  }

  dismissSnackBar() {
    this.snackBar.dismiss();
  }

  prepareAndGetRecipients(response: IRecipientsResponse): IRecipient[] {
    return response.recipients.map((user: UserForBackend) => ({
      ...user,
      recipientType: RecipientType.ACCOUNT,
    }));
  }

  private getConfirmationPopup(response: SweetAlertResult): boolean {
    if (response.value) {
      return response.value;
    }

    return false;
  }
}
