import { assignIn } from 'lodash';
import { IVenueTypeMethods } from '../../../shared/interfaces/venue-type-methods.interface';
import { IFoodbackAccount } from '../../interfaces/account/account.interface';
import { FoodbackAccountPermissions } from './foodback-account-permissions.class';
import { IVenueType } from 'app/shared/interfaces/venue-type.interface';

export class FoodbackAccount extends FoodbackAccountPermissions implements IFoodbackAccount {
  accountChildrenNameId?: string;
  companyName = '';
  isApiEnabled = false;
  isEnabled = false;
  isSingleVenueMode = false;
  location: {
    city: string;
    zipCode: string;
    street: string;
    countryCode: string;
    timezone: string;
  } = {
    city: '',
    zipCode: '',
    street: '',
    countryCode: '',
    timezone: '',
  };
  organizationNumber = '';
  uuid = '';
  brandName = '';
  weeklyReportDay: number;
  dailyReportDays: number[] = [];
  dailyReportHour = '';
  weeklyReportHour = '';
  accountInternalStatus = '';
  internalComment = '';
  hubSpotDealId = '';
  questionSet: IVenueType;
  venueTypeMethods: IVenueTypeMethods[] = [];
  isTopLevelDashboardEnabled = false;
  isAiEnabled = false;
  private readonly MAX_LANGUAGES_ENABLED_FOR_BASIC_PACKAGE = 2;

  constructor(data: IFoodbackAccount) {
    super(data);
    assignIn(this, data);
  }

  canEnableMoreLanguages(activeLanguagesCount: number): boolean {
    return activeLanguagesCount < this.MAX_LANGUAGES_ENABLED_FOR_BASIC_PACKAGE;
  }
}
