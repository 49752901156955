import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../../constants';
import { IDropdownItem } from '../../interfaces/dropdown/dropdown-item.interface';
import { ValidationErrorsInputEntry } from '../validation-errors/validation-errors.interface';
import { SelectSearchComponent } from './select-search/select-search.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  implements AfterViewInit, ControlValueAccessor, DoCheck, OnInit, OnDestroy
{
  @Input() set items(value: IDropdownItem[]) {
    this.filteredItemList.next(value);
    this.itemList = value;
    this.copyOfFilteredItemList = value;
    this.verifyControlValidity(value);
  }

  @Input() placeholder: string;
  @Input() required = false;

  @Input() set disabled(value: boolean) {
    if (this.selectCtrl) {
      this.setDisabledState(value);
    }

    this.isDisabled = value;
  }

  @Input() label: string = this.translate.instant('SHARED.SYSTEM.SELECT_ITEM');

  @Input() allSelectedPlaceholder: string =
    this.translate.instant('SHARED.SYSTEM.ALL');
  @Input() searchBoxPlaceholder: string = this.translate.instant(
    'SHARED.SYSTEM.SEARCH'
  );
  @Input() notFoundMessage: string = this.translate.instant(
    'SHARED.SYSTEM.NO_ITEMS_FOUND'
  );
  @Input() isSearchModeEnable = true;
  @Input() isMultiModeEnable = false;
  @Input() isSingleAndAllModeEnable: boolean;
  @Input() errors: ValidationErrorsInputEntry[];
  @Input() showAdditionalInfo = false;
  @Input() panelClass: string;
  @Output() readonly onSelectOpen: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() readonly onSelect: EventEmitter<IDropdownItem | IDropdownItem[]> =
    new EventEmitter<IDropdownItem | IDropdownItem[]>();
  @ViewChild('selectSearch', { static: true })
  selectSearchRef: SelectSearchComponent;
  @ViewChild('select', { static: true }) selectRef: MatSelect;

  selectCtrl: FormControl;
  searchCtrl: FormControl;
  itemList: IDropdownItem[];
  copyOfFilteredItemList: IDropdownItem[] = [];
  filteredItemList: ReplaySubject<IDropdownItem[]> = new ReplaySubject<
    IDropdownItem[]
  >(1);

  propagateChange: any;
  onTouched: any;
  hostControl: NgControl;
  isLoading = true;
  CONSTANTS: any = CONSTANTS;
  private isActiveItemsChanged = false;
  private isDisabled: boolean;

  private onDestroy: Subject<void> = new Subject<void>();

  constructor(private inj: Injector, private translate: TranslateService) {}

  ngOnInit(): void {
    this.initControls();
  }

  ngAfterViewInit(): void {
    this.selectSearchRef.isSearchModeEnable = this.isSearchModeEnable;
    this.hostControl = this.inj.get(NgControl);
    this.subscribeValueChanges();
    // this.selectRef.openedChange.pipe(takeUntil(this.onDestroy))
    //   .subscribe((state: boolean) => this.blockScrollWhenOpen(state));
    this.selectRef.options.changes
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const keyManager: ActiveDescendantKeyManager<MatOption> =
          this.selectRef._keyManager;

        if (keyManager && this.selectRef.panelOpen) {
          keyManager.setFirstItemActive();
          setTimeout(() => {
            keyManager.setFirstItemActive();
          }, CONSTANTS.DEBOUNCE_IN_SECOND.TIME_200);
        }
      });
  }

  ngDoCheck(): void {
    if (
      this.hostControl &&
      !this.hostControl.errors &&
      this.selectCtrl.errors
    ) {
      this.selectCtrl.setErrors(this.hostControl.errors);
      this.selectCtrl.updateValueAndValidity();
    }
    if (this.selectCtrl && this.selectCtrl.touched) {
      return;
    }
    if (this.hostControl && this.hostControl.touched) {
      this.selectCtrl.updateValueAndValidity();
      this.selectCtrl.markAsTouched();
    }
  }

  compareObjects(o1: IDropdownItem, o2: IDropdownItem): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectCtrl.disable({ emitEvent: false });
    } else {
      this.selectCtrl.enable({ emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: IDropdownItem | IDropdownItem[]): void {
    this.setDisabledState(this.isDisabled);
    this.isActiveItemsChanged = true;
    this.selectCtrl.setValue(value);
    this.isActiveItemsChanged = false;
  }

  toggleAll(value: boolean): void {
    if (value) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  open(status: boolean): void {
    this.onSelectOpen.emit(status);
  }

  onOptionClick(item: IDropdownItem): void {
    if (this.isSingleAndAllModeEnable) {
      this.selectCtrl.setValue([item]);
      this.propagateChange(item);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private verifyControlValidity(value: IDropdownItem[]): void {
    if (
      this.selectCtrl &&
      this.selectCtrl.value &&
      this.selectCtrl.value.id &&
      value &&
      value.length > 0
    ) {
      const selectedItem: IDropdownItem = value.find(
        (item: IDropdownItem) => item.id === this.selectCtrl.value.id
      );

      if (!selectedItem) {
        this.selectCtrl.setValue(null);
        this.propagateChange(null);
      }
    }
  }

  private selectAll(): void {
    this.selectCtrl.setValue(this.copyOfFilteredItemList);
  }

  private deselectAll(): void {
    this.selectCtrl.setValue([]);
  }

  private initControls(): void {
    this.searchCtrl = new FormControl('');
    this.selectCtrl = new FormControl(null);

    if (this.required) {
      this.selectCtrl.setValidators([Validators.required]);
      this.selectCtrl.updateValueAndValidity();
      // this.selectCtrl.markAsTouched()
    }

    this.setDisabledState(this.isDisabled);
  }

  private subscribeValueChanges(): void {
    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterItemList());
    this.selectCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value: IDropdownItem | IDropdownItem[]) => {
        if (
          !this.isActiveItemsChanged &&
          ((value && !Array.isArray(value) && value.id) || Array.isArray(value))
        ) {
          this.emitValue(value);
        }

        this.selectCtrl.setErrors(this.hostControl.errors, {
          emitEvent: false,
        });
      });
  }

  private emitValue(value: IDropdownItem | IDropdownItem[]): void {
    if (
      !this.isSingleAndAllModeEnable ||
      (Array.isArray(value) && value.length === this.itemList.length)
    ) {
      this.propagateChange(value);
      this.onSelect.emit(value);
    }
  }

  private filterItemList(): void {
    if (!this.itemList || this.itemList.length === 0) {
      return;
    }
    let search: string = this.searchCtrl.value;

    if (!search) {
      this.copyOfFilteredItemList = this.itemList;
      this.filteredItemList.next(this.itemList.slice());

      return;
    }

    search = search.toLowerCase();
    this.copyOfFilteredItemList = this.itemList.filter(
      (item: IDropdownItem) => item.text.toLowerCase().indexOf(search) > -1
    );
    this.filteredItemList.next(
      this.itemList.filter(
        (item: IDropdownItem) => item.text.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private blockScrollWhenOpen(state: boolean): void {
    // const containerRef: any = document.getElementsByClassName('ContentContainerWrapper')[0];
    //
    // if (containerRef) {
    //   // containerRef.style.overflow = state ? 'hidden' : 'unset';
    // }
    // const contentWrapper: any = document.getElementsByClassName('ContentContainerWrapper');
    //
    // document.body.style.overflow = state ? 'hidden' : 'unset';
    //
    // if (contentWrapper && contentWrapper.length > 0) {
    //   contentWrapper[0].style.overflow = state ? 'hidden' : 'unset';
    // }
  }
}
