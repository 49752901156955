import { Validators } from '@angular/forms';
import { AvailableLanguages } from '../core/providers/translator/language.contants';
import { CONSTANTS } from './constants';
import { CustomValidators } from './custom-validators';
import { AccountPackages } from './enums/account-packages.enum';

export class AdminDetails {
  avatar = '';
  name = [
    '',
    [Validators.required, Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)],
  ];
  email = [
    '',
    [
      Validators.required,
      Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
      Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
      CustomValidators.email,
    ],
  ];
  phone = ['', [CustomValidators.isOnlyDigits]];
  prefix = ['', []];
}
// @todo those forms should be divided/composed better
export class MyProfile extends AdminDetails {
  venueDailyReportSubscription = [false, []];
  venueWeeklyReportSubscription = [false, []];
  venueMonthlyReportSubscription = [false, []];
  accountWeeklyReportSubscription = [false, []];
  accountMonthlyReportSubscription = [false, []];
  language = [AvailableLanguages.britishEnglish, [Validators.required]];
}
export const AdminErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'minlength',
    text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
    rules: ['dirty'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
    rules: ['dirty'],
  },
  { name: 'email', text: 'SHARED.VALIDATION_MSG.EMAIL', rules: ['dirty'] },
  {
    name: 'notDigits',
    text: 'SHARED.VALIDATION_MSG.DIGITS_ONLY',
    rules: ['dirty'],
  },
];
export const AdminUser = {
  AdminDetails,
  AdminErrors,
};
export class AccountDetails {
  name = [
    '',
    [Validators.required, Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)],
  ];
  brandName = [
    '',
    [Validators.required, Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)],
  ];
  address = [null, [Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)]];
  country = [null];
  timezone = [null];
  code = [null, [CustomValidators.zipCodeMaxLength]];
  city = [null];
  number = [null];
  weeklyReportDay = [1, [Validators.required]];
  weeklyReportHour = ['02:00', [Validators.required]];
  // eslint-disable-next-line no-magic-numbers
  dailyReportDays = [[1, 2, 3, 4, 5], [Validators.required]];
  dailyReportHour = ['14:00', [Validators.required]];
  accountInternalStatus = ['Pilot'];
  internalComment = [''];
  hubSpotDealId = [''];
  isTopLevelDashboardEnabled = [[true, false], [Validators.required]];
  quarantineDuration = [0, []];
  isAiEnabled = [[true, false], [Validators.required]];
}
export class AccountWheelOfFortune {
  prizeText = ['', [Validators.required, Validators.maxLength(CONSTANTS.VALIDATION.PRIZE.TEXT_MAX_LENGTH)]];
  couponCode = ['', [Validators.required, Validators.maxLength(CONSTANTS.VALIDATION.PRIZE.TEXT_MAX_LENGTH)]];
  isEanEnabled = [false, [Validators.required]];
  ean = ['', [CustomValidators.barCode]];
  // eslint-disable-next-line no-magic-numbers
  winFrequency = [100, [Validators.required, Validators.min(1)]];
  // eslint-disable-next-line no-magic-numbers
  validDays = [14, [Validators.required, Validators.min(1)]];
  isActive = [false, [Validators.required]];
  isDemographicsEnabled = [false, [Validators.required]];
  isSmsEnabled = [false, [Validators.required]];
  isWinnerTextEnabled = [false, [Validators.required]];
  winnerText = [''];
  expiryDate = ['', [CustomValidators.valueNotFromPast]];
}
export const WheelOfFortuneErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED_SMALL_VERSION',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'minlength',
    text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
    rules: ['dirty'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
    rules: ['dirty'],
  },
  {
    name: 'barCode',
    text: 'SHARED.VALIDATION_MSG.BAR_CODE',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'min',
    text: 'SHARED.VALIDATION_MSG.MIN_VALUE',
    translateParams: '0',
    rules: ['touched', 'dirty'],
  },
];
export const AccountErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'minlength',
    text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
    rules: ['dirty'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
    rules: ['dirty'],
  },
  {
    name: 'barCode',
    text: 'SHARED.VALIDATION_MSG.BAR_CODE',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'isUnique',
    text: 'SHARED.VALIDATION_MSG.UNIQUE_CATEGORY_NAME',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'zipCodeMaxLength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.MAX_CODE_LENGTH,
    rules: ['dirty'],
  },
  {
    name: 'zip',
    text: 'SHARED.FORM_ERRORS.INVALID_ZIP',
    rules: ['touched', 'dirty'],
  },
];
export const GroupErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'isUnique',
    text: 'SHARED.VALIDATION_MSG.UNIQUE_GROUP_NAME',
    rules: ['touched', 'dirty'],
  },
];
export const CategoryGroupErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'isUnique',
    text: 'SHARED.VALIDATION_MSG.UNIQUE_CATEGORY_NAME',
    rules: ['touched', 'dirty'],
  },
];
export const AccountAccount = {
  AccountDetails,
  AccountWheelOfFortune,
  AccountErrors,
  WheelOfFortuneErrors,
  GroupErrors,
  CategoryGroupErrors,
};
export enum AccountUserSelectedVenues {
  ALL,
  ONLY_SELECTED,
}
export class AccountUserDetails {
  email = [
    '',
    [
      Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
      Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
      Validators.required,
      CustomValidators.email,
    ],
  ];
  name = ['', [Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)]];
  phone = ['', [CustomValidators.isOnlyDigits]];
  prefix = ['', []];
  // todo: will be handled in future version
  // role = ['', []];
}
export class AccountUserRoles {
  role = [CONSTANTS.ROLES.USER_REGULAR, []];
}
export const AccountUserErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'minlength',
    text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
    rules: ['dirty'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
    rules: ['dirty'],
  },
  { name: 'email', text: 'SHARED.VALIDATION_MSG.EMAIL', rules: ['dirty'] },
  {
    name: 'notDigits',
    text: 'SHARED.VALIDATION_MSG.DIGITS_ONLY',
    rules: ['dirty'],
  },
];
export const AccountUser = {
  AccountUserDetails,
  AccountUserErrors,
  AccountUserRoles,
};
export class VenueDetails {
  legalName = [
    '',
    [Validators.required, Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)],
  ];
  brandName = [
    '',
    [Validators.required, Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)],
  ];
  number = ['', [Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)]];
  type = ['', []];
  manager = ['', []];
  internalComment = ['', []];
  quarantineDuration = [4, []];
}
export class VenueLocation {
  address = [null, [Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)]];
  code = [null, [CustomValidators.zipCodeMaxLength]];
  city = [null, [Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)]];
  country = [null, [Validators.required]];
  timezone = [null, [Validators.required]];
}
export class VenueLocationRequired {
  address = [
    Validators.required,
    Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
    Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
  ];
  code = [
    Validators.required,
    Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
    Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
  ];
  country = [Validators.required];
  city = [
    Validators.required,
    Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
    Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
  ];
}
export class VenueWheelOfFortune {
  prizeText = ['', [Validators.required, Validators.maxLength(CONSTANTS.VALIDATION.PRIZE.TEXT_MAX_LENGTH)]];
  couponCode = ['', [Validators.required, Validators.maxLength(CONSTANTS.VALIDATION.PRIZE.TEXT_MAX_LENGTH)]];
  isEanEnabled = [false, [Validators.required]];
  ean = ['', [CustomValidators.barCode]];
  // eslint-disable-next-line no-magic-numbers
  winFrequency = [100, [Validators.min(1), Validators.required]];
  // eslint-disable-next-line no-magic-numbers
  validDays = [14, [Validators.min(1), Validators.required]];
  isActive = [false, [Validators.required]];
  isDemographicsEnabled = [false, [Validators.required]];
  isSmsEnabled = [false, [Validators.required]];
  isWinnerTextEnabled = [false, [Validators.required]];
  winnerText = [''];
  expiryDate = [''];
}
export const VenueErrors = {
  general: [
    {
      name: 'required',
      text: 'SHARED.VALIDATION_MSG.REQUIRED',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'minlength',
      text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
      rules: ['dirty'],
    },
    {
      name: 'maxlength',
      text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
      translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
      rules: ['dirty'],
    },
    { name: 'url', text: 'SHARED.VALIDATION_MSG.URL', rules: ['dirty'] },
  ],
  code: [
    {
      name: 'minlength',
      text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
      rules: ['dirty'],
    },
    {
      name: 'zip',
      translateKey: 'SHARED.FORM_ERRORS.INVALID_ZIP',
      rules: ['dirty'],
    },
    {
      name: 'required',
      translateKey: 'SHARED.FORM_ERRORS.ZIP_REQUIRED_IF_LOCATION_FILLED',
      rules: ['touched', 'dirty'],
    },
  ],
  address: [
    {
      name: 'minlength',
      text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
      rules: ['dirty'],
    },
    {
      name: 'maxlength',
      text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
      translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
      rules: ['dirty'],
    },
  ],
  city: [
    {
      name: 'minlength',
      text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
      rules: ['dirty'],
    },
    {
      name: 'maxlength',
      text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
      translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
      rules: ['dirty'],
    },
  ],
  number: [
    {
      name: 'pattern',
      text: 'SHARED.VALIDATION_MSG.INCORRECT_FORMAT',
      rules: ['dirty'],
    },
  ],
  categoryName: [
    {
      name: 'required',
      text: 'SHARED.VALIDATION_MSG.REQUIRED',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'minlength',
      text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
      translateParams: CONSTANTS.VALIDATION.CATEGORY_NAME.MIN_LENGTH,
      rules: ['dirty'],
    },
    {
      name: 'maxlength',
      text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
      translateParams: CONSTANTS.VALIDATION.CATEGORY_NAME.MAX_LENGTH,
      rules: ['dirty'],
    },
    {
      name: 'whitespace',
      text: 'SHARED.VALIDATION_MSG.WHITE_SPACES',
      rules: ['dirty'],
    },
  ],
};
export const VenueAccount = {
  VenueDetails,
  VenueLocation,
  VenueLocationRequired,
  VenueWheelOfFortune,
  VenueErrors,
};
export const VenueUserEmailValidators = {
  OPTIONAL: [
    Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
    Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
    CustomValidators.optionalEmail,
  ],
  REQUIRED: [
    Validators.required,
    Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
    Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
    CustomValidators.email,
  ],
};
export const VenueUserNameValidators = {
  OPTIONAL: [Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH), Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH)],
  REQUIRED: [
    Validators.required,
    Validators.minLength(CONSTANTS.VALIDATION.MIN_LENGTH),
    Validators.maxLength(CONSTANTS.VALIDATION.MAX_LENGTH),
  ],
};
export class VenueUserDetails {
  avatar = '';
  nameOptional = ['', VenueUserNameValidators.OPTIONAL];
  nameRequired = ['', VenueUserNameValidators.REQUIRED];
  emailOptional = ['', VenueUserEmailValidators.OPTIONAL];
  emailRequired = ['', VenueUserEmailValidators.REQUIRED];
  phone = ['', [CustomValidators.isOnlyDigits]];
  prefix = ['', []];
  isRegularUser = [{value: true, disabled: true}, []];
}
export class VenueUserRoles {
  role = [CONSTANTS.ROLES.USER_REGULAR, []];
}
export const VenueUserErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'minlength',
    text: 'SHARED.VALIDATION_MSG.MIN_LENGTH',
    rules: ['dirty'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.MAX_LENGTH,
    rules: ['dirty'],
  },
  { name: 'email', text: 'SHARED.VALIDATION_MSG.EMAIL', rules: ['dirty'] },
  {
    name: 'notDigits',
    text: 'SHARED.VALIDATION_MSG.DIGITS_ONLY',
    rules: ['dirty'],
  },
];
export const GeneralErrors = [{ name: 'url', text: 'SHARED.VALIDATION_MSG.URL', rules: ['dirty'] }];
export const GeneralQuestionsError = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched'],
  },
  {
    name: 'dateRange',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.ONE_MORE_QUESTION.QUESTION_MAX_LENGTH,
    rules: ['dirty'],
  },
  {
    name: 'whitespace',
    text: 'SHARED.VALIDATION_MSG.WHITE_SPACES',
    rules: ['touched'],
  },
  {
    name: 'min',
    text: 'SHARED.VALIDATION_MSG.MIN_VALUE',
    translateParams: '0',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'max',
    text: 'SHARED.VALIDATION_MSG.MAX_VALUE',
    translateParams: '0',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'matDatepickerParse',
    text: 'SHARED.VALIDATION_MSG.DATE_WRONG',
    rules: ['touched'],
  },
];
export const OneMoreQuestionsFormatErrors = [
  {
    name: 'required',
    text: 'SHARED.VALIDATION_MSG.REQUIRED',
    rules: ['touched'],
  },
  {
    name: 'maxlength',
    text: 'SHARED.VALIDATION_MSG.MAX_LENGTH',
    translateParams: CONSTANTS.VALIDATION.ONE_MORE_QUESTION.FORMAT_OPTIONS_LENGTH,
    rules: ['dirty', 'touched'],
  },
  {
    name: 'whitespace',
    text: 'SHARED.VALIDATION_MSG.WHITE_SPACES',
    rules: ['dirty'],
  },
];
export const VenueUser = {
  VenueUserDetails,
  VenueUserErrors,
  VenueUserRoles,
};
export class AccountStatementsGroupName {
  groupName = ['', [Validators.required]];
}
export class AccountStatementsTranslations {
  en = ['', []];
  no = ['', []];
  se = ['', []];
}
export const AccountStatement = {
  AccountStatementsGroupName,
  AccountStatementsTranslations,
};
// External export
export const FormModels = {
  AdminUser,
  AccountAccount,
  AccountUser,
  VenueAccount,
  VenueUser,
  AccountStatement,
};
