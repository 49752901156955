import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { VenueScoreboardFromBackend } from '../../../components/account/scoreboard/venue-scoreboard.interface';
import { TopLowPerformingQuestionsBackendResponse } from '../../../components/venue/dashboard/top-low-performing-questions/top-low-performing-questions.interface';
import { CONSTANTS } from '../../../shared/constants';
import { AccountScores, AccountScoresFromBackend, VenueCategoryAverageEntry } from '../../../shared/interfaces/analytics.interface';
import { DynamicDateRange } from '../../../shared/interfaces/date-range.interface';
import { UtilsService } from '../../utils/utils.service';
import { AccountService } from '../account/account.service';
import { ApiService } from '../api/api.service';
import { AnalyticsBackendService } from './analytics/analytics-backend.service';
import { CategoriesBackendService } from './categories-backend.service';
import { PayloadForVenueGroupsStatistics, PayloadForVenueStatistics } from './analytics/analytics-params.interface';
import { SharedAnalyticsBackendService } from './shared-analytics-backend.service';
import { PayloadForWinnerStatistic } from 'app/core/interfaces/payload-for-winner-statistic.interface';
import { WinnerStatisticFromBackend } from 'app/components/account/winner-statistic/winner-statistic-acocunt.interfaces';
import { ApiResponse } from 'app/shared/interfaces/api-response.interface';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class AccountAnalyticsBackendService extends SharedAnalyticsBackendService {
  private accountAnalyticsPath: string = `${environment.apiBaseUrl.handbook}/surveys/analytics/accounts`;
  private accountAnalyticExportPath: string = `${environment.apiBaseUrl.handbook}/surveys/exports/accounts`;
  private rewardsPath = `${environment.apiBaseUrl.handbook}/surveys/rewards/accounts`;

  private defaultWinnerStatisticBody: PayloadForWinnerStatistic = {
    page: 1,
    limit: 10,
    dateFrom: null,
    dateTo: null,
  };

  constructor(
    accountService: AccountService,
    apiService: ApiService,
    categoriesBackendService: CategoriesBackendService,
    utilsService: UtilsService,
    private notificationService: NotificationService
  ) {
    super(accountService, apiService, categoriesBackendService, utilsService);
    this.analyticsPath = `${environment.apiBaseUrl.handbook}/surveys/analytics/accounts`;
  }

  getScoreboardVenuesList$(accountUuid: string, payload: PayloadForVenueStatistics): Observable<VenueScoreboardFromBackend> {
    return this.apiService
      .post$(`${this.accountAnalyticsPath}/${accountUuid}/${CONSTANTS.ENDPOINTS.SCOREBOARD}`, payload)
      .pipe(map((response: any) => response.content));
  }

  getScoreboardVenuesListExcel$(accountUuid: string, payload: PayloadForVenueStatistics): Observable<VenueScoreboardFromBackend> {
    return this.apiService.post$(`${this.accountAnalyticExportPath}/${accountUuid}/${CONSTANTS.ENDPOINTS.SPREADSHEET}/${CONSTANTS.ENDPOINTS.SCOREBOARD}`, payload)
      .pipe(map((response: any) => response.content));
  }

  getScoreboardGroupList$(accountUuid: string, payload: PayloadForVenueGroupsStatistics): Observable<VenueScoreboardFromBackend> {
    return this.apiService
      .post$(`${this.accountAnalyticsPath}/${accountUuid}/${CONSTANTS.ENDPOINTS.SCOREBOARD}/groups`, payload)
      .pipe(map((response: any) => response.content));
  }

  getCategoryAveragesForAccount$(
    accountUuid: string,
    dateRange: DynamicDateRange,
    venueUuids: string[]
  ): Observable<VenueCategoryAverageEntry[]> {
    return this.makeAnalyticsRequest$(CONSTANTS.ENDPOINTS.CATEGORY_AVERAGES, accountUuid, dateRange, venueUuids);
  }

  getAccountScores$(accountUuid: string, dateRange: DynamicDateRange): Observable<AccountScores> {
    return this.apiService
      .get$(`${this.accountAnalyticsPath}/${accountUuid}/${CONSTANTS.ENDPOINTS.SCORES}`, {
        params: this.getRequestParams(dateRange),
      })
      .pipe(
        map(response => response.content),
        map((response: AccountScoresFromBackend) => ({
          currentCount: response.currentCount.slice(),
          currentScores: response.currentScores.map(AnalyticsBackendService.mapScoreFromBackendToScore),
          previousScores: response.previousScores.map(AnalyticsBackendService.mapScoreFromBackendToScore),
        }))
      );
  }

  getAccountTopLowPerformingQuestions$(
    accountUuid: string,
    dateRange: DynamicDateRange,
    venueUuids: string[]
  ): Observable<TopLowPerformingQuestionsBackendResponse[]> {
    const endpoint = `${CONSTANTS.ENDPOINTS.STATEMENTS}/${CONSTANTS.ENDPOINTS.PERFORMANCE}`;

    return this.makeAnalyticsRequest$(endpoint, accountUuid, dateRange, venueUuids);
  }

  getWinnerStatisticList$(accountUuid: string, restrictions?: PayloadForWinnerStatistic): Observable<WinnerStatisticFromBackend> {
    const objToSend: PayloadForWinnerStatistic = restrictions || this.defaultWinnerStatisticBody;

    return this.apiService.post$(`${this.rewardsPath}/${accountUuid}`, objToSend).pipe(
      map((response: ApiResponse): WinnerStatisticFromBackend[] => response.content),
      catchError((error): Observable<any> => {
        this.notificationService.error(error.errorMessage);

        return throwError('Error with "foodbacks" request', error);
      })
    );
  }

  protected makeAnalyticsRequest$<T>(endpoint: string, uuid: string, dateRange: DynamicDateRange, venues?: string[]): Observable<T> {
    return this.apiService
      .post$(`${this.analyticsPath}/${uuid}/${endpoint}`, { venues }, { params: this.getRequestParams(dateRange) })
      .pipe(map(response => response.content));
  }
}
