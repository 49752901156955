<app-data-card-widget-panel class="DataCardWidget">

  <ng-container header>
    <span *ngIf="showIcon" class="DataCardWidget-headerIcon align-middle fb-font fb-font-18px fb-font-smiley"></span>
    <div class="inline">
      <span class="align-middle" [class.text-uppercase]="isTitleUppercase">{{ title | translate }}</span>
    </div>
  </ng-container>
  <app-percent-circle *ngIf="!isScoreDistribution" content class="DataCardWidget-circle" [goal]="goal"
    [useCustomContent]="true" [percentValue]="percentValue" [variant]="PercentCircleVariant.BLUE">
    <div custom-content>
      <span class="h1">{{dataStatisticsSet?.overallExperienceAverage || 0 | number:
        CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT}}</span>
      <span class="h1" *ngIf="dataStatisticsSet?.prevOverallExperienceAverage">
        <ng-container [ngTemplateOutlet]="arrowIndicator"
          [ngTemplateOutletContext]="{isHigher: dataStatisticsSet?.overallExperienceAverage > dataStatisticsSet?.prevOverallExperienceAverage}">
        </ng-container>
      </span>
      <br />
      <span *ngIf="isAccessToGoals" class="h4 fb-text-info">
        {{ 'SHARED.SYSTEM.GOAL' | translate }}
        {{ goal | number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT }}
      </span>
      <span *ngIf="dataStatisticsSet?.prevOverallExperienceAverage" class="h4 fb-text-info">
        {{ 'SHARED.TOP_LEVEL_DASHBOARD.PREV_VALUE' | translate:{'0': (dataStatisticsSet?.prevOverallExperienceAverage |
        number: CONSTANTS.NUMBER_PIPE.DEFAULT_FORMAT)} }}
      </span>
    </div>
    <ng-template #arrowIndicator let-isHigher="isHigher">
      <i *ngIf="isHigher" class="mdi mdi-arrow-up DataCardWidget-arrow--up"></i>
      <i *ngIf="!isHigher" class="mdi mdi-arrow-down DataCardWidget-arrow--down"></i>
    </ng-template>
  </app-percent-circle>
  <ng-container content *ngIf="isScoreDistribution">
    <fusioncharts [width]="chartConfig.width" [height]="chartConfig.height" [type]="chartConfig.type"
      [dataFormat]="chartConfig.dataFormat" [dataSource]="chartConfig.dataSource">
    </fusioncharts>
    <app-score-distribution *ngIf="isScoreDistribution" [data]="scoresDistributionData"></app-score-distribution>
  </ng-container>


  <div footer *ngIf="dataStatisticsSet" class="bt DataCardWidget-statistics">
    <ng-container *ngFor="let entry of dataStatisticsSet.entries">

      <div *ngIf="entry.title !== 'SHARED.SYSTEM.EMAILS'" class="DataCardWidgetStatisticsEntry"
        class="DataCardWidgetStatisticsEntry">
        <div class="DataCardWidgetStatisticsEntry-number">
          <a *ngIf="entry.mainUrl && entry.mainUrl.url && canRedirectToAnotherPage"
            class="DataCardWidgetStatisticsEntry-link" [routerLink]="getRouterLink(entry)"
            [queryParams]="getParamsForRoute(entry)">{{ entry.data | number: CONSTANTS.NUMBER_PIPE.INTEGERS }}</a>
          <a *ngIf="!canRedirectToAnotherPage" class="DataCardWidgetStatisticsEntry-link"
            (click)="scrollToElement(entry)">{{ entry.data | number: CONSTANTS.NUMBER_PIPE.INTEGERS }}</a>
          <span *ngIf="!entry.mainUrl || (entry.mainUrl && !entry.mainUrl.url)">{{
            entry.data
            }}</span>
          <span
            *ngIf="dataStatisticsSet.totalFoodbacksCount != null && !(dataStatisticsSet.totalFoodbacksCount < entry.data) && entry.title === 'SHARED.SYSTEM.FOODBACKS'"
            style="font-weight: 100"> ({{ dataStatisticsSet.totalFoodbacksCount }})</span>
          <span
            *ngIf="dataStatisticsSet.totalCommentsCount != null && !(dataStatisticsSet.totalCommentsCount < entry.data) && entry.title === 'SHARED.SYSTEM.COMMENTS'"
            style="font-weight: 100"> ({{ dataStatisticsSet.totalCommentsCount }})</span>
          <a *ngIf="entry.redirect && !accountContext" [routerLink]="entry.redirect.url"
            [queryParams]="{ venues: venues, isVenuesExcluded: isVenuesExcluded }"
            class="link-unstyled fa fa-cog DataCardWidgetStatisticsEntry__url"
            [attr.title]="entry.redirect.title | translate"></a>
        </div>
        <div class="DataCardWidgetStatisticsEntry-text">
          {{ entry.title | translate }}
        </div>
      </div>
    </ng-container>
  </div>
</app-data-card-widget-panel>