import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { WheelOfFortuneBackend, WheelOfFortuneBackendPayload } from '../../../shared/form-models-interfaces';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { VenueDemographicsConfigurationVariant } from '../../../shared/interfaces/bill-folders';
import { ApiService } from '../api/api.service';
import { ValidationService } from './validation.service';
import { request } from 'http';
import * as moment from "moment";
import { NotificationService } from '../notification/notification.service';
import { ContextObject } from 'app/shared/interfaces/login-object';
import { ContextService } from '../context/context.service';

@Injectable()
export class WheelOfFortuneBackendService {
  private venuesPath = `${environment.apiBaseUrl.handbook}/surveys/wheels/venues`;
  private accountsPath = `${environment.apiBaseUrl.handbook}/surveys/wheels/accounts`;
  private accountsPathUpdate = `${environment.apiBaseUrl.handbook}/surveys/wheels`;
  private getWheelPath = `${environment.apiBaseUrl.handbook}/surveys/wheels`;
  private isWheelEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService, private validationService: ValidationService, private notificationService: NotificationService, private contextService: ContextService) { }

  checkIsWheelEnable(): BehaviorSubject<boolean> {
    return this.isWheelEnable;
  }

  getWheelOfFortuneSettings$(context: string, uuid: string): Observable<WheelOfFortuneBackend> {
    const requestPath = `${context === CONSTANTS.CONTEXT.ACCOUNT ? this.accountsPath : this.venuesPath}/${uuid}`;

    return this.apiService.get$(requestPath).pipe(
      map(response => response.content),
      tap(response => {
        this.isWheelEnable.next(response.isActive);
      })
    );
  }

  getWheelOfFortuneSettingsByType$(context: string, uuid: string, type: string): Observable<WheelOfFortuneBackend> {
    const requestPath = `${this.getWheelPath}/targets/${uuid}/reward-types/${type}`;

    return this.apiService.get$(requestPath).pipe(
      map(response => response.content),
      tap(response => {
        this.isWheelEnable.next(response.isActive);
      })
    );
  }

  sendFileWithCoupons$(file) {
    const requestPath = `${environment.apiBaseUrl.handbook}/storage/files`;
    const fd: FormData = new FormData();
    fd.append(
      "file",
      file,
      file.name
    );
    return this.apiService.post$(requestPath, fd, { headers: { 'Content-Type': undefined } }).pipe(
      map(response => response.content),
      tap(response => {
        return response.uuid;
      })
    )
  }

  getFileWithAllCouponsTemplate$(): void {
    const csvData = `code,expiresAt\ncode1,21/07/2025\ncode2,2025-12-15\ncode3`;

    const blob = new Blob([csvData], { type: 'text/csv' });
    const downloadURL = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = downloadURL;
    a.download = 'Coupons - template.csv';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  }

  getFileWithAllCoupons$(uuid, contextName): Observable<any> {
    const requestPath = `${environment.apiBaseUrl.handbook}/surveys/wheels/targets/${uuid}/coupons/download`;
    return this.apiService.get$(requestPath, {
      responseType: 'text',
    }).pipe(
      map((response) => {
        const parsedData = this.parseCSV(response.content);
        const csvOutput = this.convertToCSV(parsedData);

        const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        const todayDate = moment(new Date()).format("DD.MM.YYYY")
        a.href = url;
        a.download = todayDate + ' - Coupon status for ' + contextName +  '.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
      }),
      catchError((error: any) => {
        this.notificationService.addSnackBarMsg('There are no coupons to download', 'error', 5000);
        return observableThrowError(error);
      })
    )
  }

  private parseCSV(csv: string): any[] {
    const lines = csv.split('\n').map(line => line.trim()).filter(line => line.length > 0);

    const headers = lines[0].split(',').map(header => header.trim().replace(/"/g, ''));

    const rows = lines.slice(1).map(line => {
      const values = line.split(',').map(value => value.trim().replace(/"/g, ''));
      const rowObject: any = {};
      headers.forEach((header, index) => {
        rowObject[header] = values[index];
      });
      return rowObject;
    });

    return rows;
  }

  private convertToCSV(data: any): string {
    if (!Array.isArray(data)) {
      data = [data];
    }

    if (data.length === 0 || typeof data[0] !== 'object') {
      throw new Error('Invalid data format for CSV conversion');
    }

    const header = Object.keys(data[0]);
    const rows = data.map(item => header.map(key => this.formatCSVCell(item[key])));

    return [header, ...rows].map(row => row.join(',')).join('\n');
  }
  private formatCSVCell(cell: any): string {
    if (cell === null || cell === undefined) return '';
    if (typeof cell === 'string' && (cell.includes(',') || cell.includes('"'))) {
      return `"${cell.replace(/"/g, '""')}"`;
    }
    return String(cell);
  }

  getDeleteAllCouponsInfo$(uuid: string) {
    const requestPath = `${environment.apiBaseUrl.handbook}/surveys/wheels/targets/${uuid}/coupons/delete-info`;

    return this.apiService.get$(requestPath).pipe(map(response => response.content));
  }

  deleteAllCoupons$(uuid: string) {
    const requestPath = `${environment.apiBaseUrl.handbook}/surveys/wheels/targets/${uuid}/coupons`;

    return this.apiService.delete$(requestPath).pipe(map(response => response.content));
  }

  updateWheelOfFortuneSettings$(
    context: string,
    uuid: string,
    wheelOfFortuneSettings: WheelOfFortuneBackendPayload,
    rewardType: string,
    fileUuid: string,
    accountUuid?: string
  ): Observable<ApiResponse> {
    const requestPath = `${this.accountsPathUpdate}`;
    const accountUuidValue = accountUuid ? accountUuid : uuid;

    const objectWheelOfFortuneSettings = this.prepareWheelConfigObject(wheelOfFortuneSettings, rewardType, context, uuid, accountUuidValue, fileUuid);
    return this.apiService
      .put$(requestPath, objectWheelOfFortuneSettings)
      .pipe(
        tap(() => {
          this.isWheelEnable.next(wheelOfFortuneSettings.isActive);
        })
      )
      .pipe(
        catchError((error): Observable<ApiResponse> => {
          if (error.error.validationErrors) {
            this.notificationService.addSnackBarMsg(error.error.validationErrors[0], 'error', 10000);
          }

          return observableThrowError('Error with "update Wheel of fortune settings" request', error);
        })
      );
  }

  prepareWheelConfigObject(config, rewardType: string, context: string, uuid: string, accountUuid: string, fileUuid: string) {
    if (rewardType === CONSTANTS.REWARD_TYPE.DIGITAL_VOUCHER) {
      return {
        rewardType: rewardType,
        target: {
          uuid: uuid,
          type: context
        },
        accountUuid: accountUuid,
        prizeText: config.prizeText,
        winnerText: config.winnerText ? config.winnerText : null,
        isWinnerTextEnabled: config.isWinnerTextEnabled,
        winFrequency: config.winFrequency,
        isActive: config.isActive,
        isEnforced: config.wheelEnforcement,
        isSmsEnabled: config.isSmsEnabled,
        isDemographicsEnabled: config.isDemographicEnabled ? config.isDemographicEnabled : false,
        rewards: {
          validDays: config.validDays,
          ean: config.ean,
        isEanEnabled: config.isEanEnabled
        }
      }
    } else if (rewardType === CONSTANTS.REWARD_TYPE.ONGOING_COUPON) {
      return {
        rewardType: rewardType,
        target: {
          uuid: uuid,
          type: context
        },
        accountUuid: accountUuid,
        prizeText: config.prizeText,
        winnerText: config.winnerText ? config.winnerText : null,
        isWinnerTextEnabled: config.isWinnerTextEnabled,
        winFrequency: config.winFrequency,
        isActive: config.isActive,
        isEnforced: config.wheelEnforcement,
        isSmsEnabled: config.isSmsEnabled,
        isDemographicsEnabled: config.isDemographicEnabled ? config.isDemographicEnabled : false,
        rewards: {
          code: config.couponCode,
          expiresAt: config.expiryDate ? moment(config.expiryDate).format('YYYY-MM-DD HH:mm:ss') : null
        }
      }
    } else if (rewardType === CONSTANTS.REWARD_TYPE.SINGLE_USE_COUPON) {
      return {
        rewardType: rewardType,
        target: {
          uuid: uuid,
          type: context
        },
        accountUuid: accountUuid,
        prizeText: config.prizeText,
        winnerText: config.winnerText ? config.winnerText : null,
        isWinnerTextEnabled: config.isWinnerTextEnabled,
        winFrequency: config.winFrequency,
        isActive: config.isActive,
        isEnforced: config.wheelEnforcement,
        isSmsEnabled: config.isSmsEnabled,
        isDemographicsEnabled: config.isDemographicEnabled,
        rewards: {
          fileUuid: fileUuid ? fileUuid : null
        }
      }
    }
  }

  setFormControlValidator(control: AbstractControl, newValidator: ValidatorFn | ValidatorFn[] | null) {
    control.setValidators(newValidator);
    control.updateValueAndValidity();
  }

  resetFormControl(control: AbstractControl) {
    control.clearValidators();
    control.updateValueAndValidity();
    control.reset();
  }

  areDemographicsActive(demographicsVariant: VenueDemographicsConfigurationVariant): boolean {
    return demographicsVariant === VenueDemographicsConfigurationVariant.ALL;
  }
}
