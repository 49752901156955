import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FeaturedFoodbacksResponse } from '../../../components/venue/dashboard/featured-foodbacks/featured-foodbacks.interface';
import { TopLowPerformingQuestionsBackendResponse } from '../../../components/venue/dashboard/top-low-performing-questions/top-low-performing-questions.interface';
import { DateGroupPickerValue } from '../../../shared/components';
import { CONSTANTS } from '../../../shared/constants';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { AccountService } from '../account/account.service';
import { AnalyticsBackendService } from './analytics/analytics-backend.service';
import { ApiService } from '../api/api.service';
import { CategoriesBackendService } from './categories-backend.service';
import { DateRange, DynamicDateRange } from '../../../shared/interfaces/date-range.interface';
import { FileSaverService } from './file-saver-service.service';
import {
  IVenueScores,
  UnhappyCustomersDetails,
  UserStatistics,
  VenueAgeDistributionFromBackend,
  VenueAverageAgeFromBackend,
  VenueCategoryAverageEntry,
  VenueGenderDistributionFromBackend,
  VenueLanguageDistributionFromBackend,
  VenueScoresFromBackend,
  VenueStatistics,
} from '../../../shared/interfaces/analytics.interface';
import { NotificationService } from '../notification/notification.service';
import { PayloadForFoodbacks } from '../../interfaces/payload-for-foodbacks.interface';
import { PayloadForWinnerStatistic } from '../../interfaces/payload-for-winner-statistic.interface';
import { SharedAnalyticsBackendService } from './shared-analytics-backend.service';
import { UtilsService } from '../../utils/utils.service';
import { WinnerStatisticFromBackend } from '../../../components/venue/winner-statistic/winner-statistic.interfaces';
import { IDateRangePayload } from './analytics/analytics-params.interface';

@Injectable()
export class VenueAnalyticsBackendService extends SharedAnalyticsBackendService {
  private defaultWinnerStatisticBody: PayloadForWinnerStatistic = {
    page: 1,
    limit: 10,
    dateFrom: null,
    dateTo: null,
  };
  private takeawayPath = `${environment.apiBaseUrl.handbook}/surveys/rewards/venues`;

  constructor(
    accountService: AccountService,
    apiService: ApiService,
    categoriesBackendService: CategoriesBackendService,
    utilsService: UtilsService,
    private notificationService: NotificationService,
    private fileSaverService: FileSaverService
  ) {
    super(accountService, apiService, categoriesBackendService, utilsService);
    this.analyticsPath = `${environment.apiBaseUrl.handbook}/surveys/analytics/venues`;
  }

  getVenueStatistics$(venueUuid: string, dateRange: DynamicDateRange): Observable<VenueStatistics> {
    return this.makeRequestForVenue$(CONSTANTS.ENDPOINTS.STATISTICS, venueUuid, dateRange);
  }

  getCategoryAverages$(venueUuid: string, dateRange: DynamicDateRange): Observable<VenueCategoryAverageEntry[]> {
    return this.makeRequestForVenue$(CONSTANTS.ENDPOINTS.CATEGORY_AVERAGES, venueUuid, dateRange);
  }

  getVenueOverallExperienceScores$(venueUuid: string, dateRange: DynamicDateRange): Observable<IVenueScores> {
    return this.categoriesBackendService.getOverallExperienceDetails(venueUuid, CONSTANTS.CONTEXT.VENUE).pipe(
      mergeMap(overallExperience => this.makeRequestForVenue$(`category/${overallExperience.uuid}/scores`, venueUuid, dateRange)),
      map((response: VenueScoresFromBackend) => ({
        currentCount: response.currentCount.slice(),
        currentScores: response.currentScores.map(AnalyticsBackendService.mapScoreFromBackendToScore),
        previousScores: response.previousScores.map(AnalyticsBackendService.mapScoreFromBackendToScore),
      }))
    );
  }

  getVenueGenderDistribution$(venueUuid: string): Observable<VenueGenderDistributionFromBackend> {
    return this.apiService.get$(`${this.analyticsPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.DEMOGRAPHICS}/distributions/gender`).pipe(
      map(response => response.content),
      map((response: VenueGenderDistributionFromBackend) => AnalyticsBackendService.keysToUpperCase(response))
    );
  }

  getVenueLanguageDistribution(venueUuid: string): Observable<VenueLanguageDistributionFromBackend> {
    return this.apiService.get$(`${this.analyticsPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.DEMOGRAPHICS}/distributions/language`).pipe(
      map(response => response.content),
      map((response: VenueLanguageDistributionFromBackend) => AnalyticsBackendService.keysToUpperCase(response))
    );
  }

  getVenueAgeDistribution$(venueUuid: string): Observable<VenueAgeDistributionFromBackend> {
    return this.apiService
      .get$(`${this.analyticsPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.DEMOGRAPHICS}/distributions/ageGroup/gender`)
      .pipe(
        map(response => response.content),
        map((response: VenueAgeDistributionFromBackend) =>
          Object.keys(response).reduce((reduced, ageGroup) => {
            reduced[ageGroup] = AnalyticsBackendService.keysToUpperCase(response[ageGroup]);

            return reduced;
          }, {})
        )
      );
  }

  getVenueAverageAge$(venueUuid: string): Observable<VenueAverageAgeFromBackend> {
    return this.apiService
      .get$(`${this.analyticsPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.DEMOGRAPHICS}/averages/ageGroup`)
      .pipe(map(response => response.content));
  }

  getVenueDayByDayScores$(venueUuid: string, dateRange: DynamicDateRange): Observable<any> {
    return this.categoriesBackendService
      .getOverallExperienceDetails(venueUuid, CONSTANTS.CONTEXT.VENUE)
      .pipe(
        mergeMap(overallExperience =>
          this.makeRequestForVenue$(`category/${overallExperience.uuid}/scores/distributions/dayOfWeek`, venueUuid, dateRange)
        )
      );
  }

  getVenueHourByHourScores$(venueUuid: string, dateRange: DynamicDateRange): Observable<any> {
    return this.categoriesBackendService
      .getOverallExperienceDetails(venueUuid, CONSTANTS.CONTEXT.VENUE)
      .pipe(
        mergeMap(overallExperience =>
          this.makeRequestForVenue$(`category/${overallExperience.uuid}/scores/distributions/hourRanges`, venueUuid, dateRange)
        )
      );
  }

  getVenueScores$(venueUuid: string, dateRange: DynamicDateRange): Observable<IVenueScores> {
    return this.makeRequestForVenue$(CONSTANTS.ENDPOINTS.SCORES, venueUuid, dateRange).pipe(
      map((response: VenueScoresFromBackend) => ({
        currentCount: response.currentCount.slice(),
        currentScores: response.currentScores.map(AnalyticsBackendService.mapScoreFromBackendToScore),
        previousScores: response.previousScores.map(AnalyticsBackendService.mapScoreFromBackendToScore),
      }))
    );
  }

  getWinnerStatisticList$(venueUuid: string, restrictions?: PayloadForWinnerStatistic): Observable<WinnerStatisticFromBackend> {
    const objToSend: PayloadForWinnerStatistic = restrictions || this.defaultWinnerStatisticBody;

    return this.apiService.post$(`${this.takeawayPath}/${venueUuid}`, objToSend).pipe(
      map((response: ApiResponse): WinnerStatisticFromBackend[] => response.content),
      catchError((error): Observable<any> => {
        this.notificationService.error(error.errorMessage);

        return throwError('Error with "foodbacks" request', error);
      })
    );
  }

  getVenueUnhappyCustomers$(venueUuid: string, dateRange: DynamicDateRange): Observable<UnhappyCustomersDetails> {
    // this endpoint explicitly requires dateRange.groupBy to be CUSTOM
    const normalizedDateRange: DateRange = {
      ...dateRange,
      groupBy: DateGroupPickerValue.CUSTOM,
    };
    const endpoint = `${CONSTANTS.ENDPOINTS.UNHAPPY_CUSTOMER}/${CONSTANTS.ENDPOINTS.SUMMARY}`;

    return this.makeRequestForVenue$(endpoint, venueUuid, normalizedDateRange);
  }

  getVenueFeaturedFoodbacks$(venueUuid: string, dateRange: DynamicDateRange): Observable<FeaturedFoodbacksResponse> {
    return this.makeRequestForVenue$(CONSTANTS.ENDPOINTS.FEATURED_FOODBACKS, venueUuid, dateRange);
  }

  getVenueTopLowPerformingQuestions$(
    venueUuid: string,
    dateRange: DynamicDateRange
  ): Observable<TopLowPerformingQuestionsBackendResponse[]> {
    const endpoint = `${CONSTANTS.ENDPOINTS.STATEMENTS}/${CONSTANTS.ENDPOINTS.PERFORMANCE}`;

    return this.makeRequestForVenue$(endpoint, venueUuid, dateRange);
  }

  exportDemographicsToFile$(venueUuid: string, payload?: Partial<PayloadForFoodbacks>): Observable<void> {
    const exportPath = `${environment.apiBaseUrl.handbook}/surveys/export/venues`;

    return this.apiService
      .get$(`${exportPath}/${venueUuid}/${CONSTANTS.ENDPOINTS.ANALYTICS}/${CONSTANTS.ENDPOINTS.FOODBACKS}`, {
        params: this.getExportParams(payload),
        responseType: 'blob',
      })
      .pipe(
        map(response => {
          const fileName = response.headers.get('x-filename');
          const fileData = response.content;

          this.fileSaverService.fetchXLSXFile(fileData, fileName);
        })
      );
  }

  private getExportParams(payload: Partial<PayloadForFoodbacks>): HttpParams {
    let params: HttpParams = new HttpParams();

    for (const param in payload) {
      if (payload[param] !== null) {
        params = params.append(param, payload[param]);
      }
    }

    return params;
  }

  private makeRequestForVenue$(endpoint: string, venueUuid: string, dateRange: DynamicDateRange): Observable<any> {
    return this.apiService
      .get$(`${this.analyticsPath}/${venueUuid}/${endpoint}`, {
        params: this.getRequestParams(dateRange),
      })
      .pipe(map(response => response.content));
  }
}
