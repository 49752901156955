import { Auth0LockConstructorOptions } from 'auth0-lock';

export const authConfig: { options: Auth0LockConstructorOptions } = {
  options: {
    theme: {
      logo: '../../../assets/svg/foodback-login-logo.png',
      primaryColor: '#DC3855',
    },
    closable: false,
    languageDictionary: {
      title: 'Welcome to Foodback',
      signupTitle: 'Welcome to Foodback',
      signUpSubmitLabel: "Let's get started",
    },
    avatar: null,
    auth: {
      redirect: false,
      sso: false,
    },
    configurationBaseUrl: 'https://cdn.eu.auth0.com',
    container: 'login-auth-container',
  },
};
