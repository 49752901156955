import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FoodbackAccount } from '../../../core/classes/account/account.class';
import { AccountService } from '../../../core/providers/account/account.service';
import { CONSTANTS } from '../../constants';
import { TableProgressBarService } from '../table-progress-bar.service';

@Component({
  selector: 'app-table-progress-bar',
  templateUrl: './table-progress-bar.component.html',
  styleUrls: ['./table-progress-bar.component.scss'],
})
export class TableProgressBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rating: number;
  @Input() goal: number = CONSTANTS.FOODBACK.DEFAULT_GOAL;
  @Input() height: number = 8;
  progressBarClass: string[];
  ratingAsPercent: number;
  account: FoodbackAccount = new FoodbackAccount(null);

  constructor(private accountService: AccountService, private tableProgressBarService: TableProgressBarService) {}

  getGoalPosition(): string {
    // eslint-disable-next-line no-magic-numbers
    return `calc(100% - ${((this.goal || 0) / CONSTANTS.FOODBACK.RANK_SCALE) * 100}%)`;
  }

  ngOnInit() {
    this.getAccount$().subscribe((account: FoodbackAccount) => {
      this.account = account;
      this.setProgressBarClass();
    });
    this.setProgressBarClass();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rating && changes.rating.currentValue) {
      this.ratingAsPercent = this.tableProgressBarService.convertRatingToPercent(this.rating);
      this.setProgressBarClass();
    }
  }

  ngOnDestroy() {}

  private getAccount$(): Observable<FoodbackAccount> {
    return this.accountService.getAccount$();
  }

  private setProgressBarClass() {
    this.progressBarClass = [
      this.tableProgressBarService.getWidthClassForRating(this.ratingAsPercent),
      this.goal
        ? this.tableProgressBarService.getColorClassForRating(this.rating, this.goal)
        : this.tableProgressBarService.getColorClassForRating(this.rating),
    ];
  }
}
