import { Injectable } from '@angular/core';
import { find } from 'lodash';
import * as moment from 'moment-timezone';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject, forkJoin, throwError as observableThrowError, of } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { CONSTANTS } from '../../../shared/constants';
import { VenueFromBackend } from '../../../shared/form-models-interfaces';
import { ContextObject } from '../../../shared/interfaces/login-object';
import { PermissionsEntry, VenuePermissionsEntry } from '../../../shared/interfaces/permissions.interface';
import { IFoodbackAccount } from '../../interfaces/account/account.interface';
import { AccountService } from '../account/account.service';
import { PermissionsBackendService } from '../backend/permissions-backend.service';
import { UsersBackendService } from '../backend/users-backend.service';
import { AvailableLanguages } from '../translator/language.contants';

@Injectable()
export class ContextService {
  onSessionContextUuidChange$: Subject<void> = new Subject();
  accountChildrenNameTranslations = [
    {
      name: 'venue',
      languages: [
        {
        code: AvailableLanguages.britishEnglish,
        singular: 'Venue',
        plural: 'Venues'
        },
        {
        code: AvailableLanguages.french,
        singular: 'Venue',
        plural: 'Venues'
        },
        {
        code: AvailableLanguages.german,
        singular: 'Venue',
        plural: 'Venues'
        },
        {
        code: AvailableLanguages.italian,
        singular: 'Venue',
        plural: 'Venues'
        },
        {
        code: AvailableLanguages.spanish,
        singular: 'Venue',
        plural: 'Venues'
        },
      ]
    },
    {
      name: 'room',
      languages: [
        {
        code: AvailableLanguages.britishEnglish,
        singular: 'Room',
        plural: 'Rooms'
        },
        {
        code: AvailableLanguages.french,
        singular: 'Room',
        plural: 'Rooms'
        },
        {
        code: AvailableLanguages.german,
        singular: 'Room',
        plural: 'Rooms'
        },
        {
        code: AvailableLanguages.italian,
        singular: 'Room',
        plural: 'Rooms'
        },
        {
        code: AvailableLanguages.spanish,
        singular: 'Room',
        plural: 'Rooms'
        },
      ]
    },
    {
      name: 'product',
      languages: [
        {
        code: AvailableLanguages.britishEnglish,
        singular: 'Product',
        plural: 'Products'
        },
        {
        code: AvailableLanguages.french,
        singular: 'Product',
        plural: 'Products'
        },
        {
        code: AvailableLanguages.german,
        singular: 'Product',
        plural: 'Products'
        },
        {
        code: AvailableLanguages.italian,
        singular: 'Product',
        plural: 'Products'
        },
        {
        code: AvailableLanguages.spanish,
        singular: 'Product',
        plural: 'Products'
        },
      ]
    },
  ]
  private currentContext: BehaviorSubject<ContextObject> = new BehaviorSubject(null);

  constructor(
    private accountService: AccountService,
    private usersBackendService: UsersBackendService,
    private permissionsBackendService: PermissionsBackendService,
    private localStorage: LocalStorageService,
  ) {
    this.updateAccountOnContextChange();
  }

  static isAccountContext({ type }: ContextObject): boolean {
    return type === CONSTANTS.CONTEXT.ACCOUNT;
  }

  static isVenueContext({ type }: ContextObject): boolean {
    return type === CONSTANTS.CONTEXT.VENUE;
  }

  getContext$(): Observable<ContextObject> {
    return this.currentContext.pipe(filter(item => item !== null));
  }

  isContextSet(): boolean {
    return this.currentContext.getValue() !== null;
  }

  ensureProperContext$(contextType: string, uuid: string): Observable<any> {
    let currentContext = this.currentContext.getValue();
    let isFallbackContextUsed = false;

    if (!currentContext) {
      currentContext = this.localStorage.retrieve(CONSTANTS.SESSION.FALLBACK_CONTEXT);
      isFallbackContextUsed = true;
    }
    if (isFallbackContextUsed || currentContext === null || currentContext.type !== contextType || currentContext.uuid !== uuid) {
      this.currentContext.next(null);

      return this.switchContext$(contextType, uuid).pipe(
        mergeMap(() => this.canVenueContextSwitchToAccountContext$(currentContext, contextType, uuid)),
        mergeMap(result => (result ? of(null) : observableThrowError(new Error())))
      );
    }

    return of(null);
  }

  setFallbackContext(context: ContextObject) {
    this.localStorage.store(CONSTANTS.SESSION.FALLBACK_CONTEXT, context);
  }

  switchToFallbackContext$(): Observable<ContextObject> {
    const fallbackContext: ContextObject = this.localStorage.retrieve(CONSTANTS.SESSION.FALLBACK_CONTEXT);

    if (!fallbackContext) {
      return observableThrowError(new Error());
    }
    const currentContext = this.currentContext.getValue();

    if (currentContext && currentContext.type === fallbackContext.type && currentContext.uuid === fallbackContext.uuid) {
      return of(currentContext);
    }

    return this.switchContext$(fallbackContext.type, fallbackContext.uuid);
  }

  setContext(newValue: ContextObject): void {
    this.permissionsBackendService.setRedirectStatus(false);
    this.currentContext.next(newValue);
  }

  clearContext(): void {
    this.localStorage.clear(CONSTANTS.SESSION.FALLBACK_CONTEXT);
  }

  switchContext$(type: string, uuid: string): Observable<ContextObject> {
    return this.convertDefaultItemToContext$({ type, uuid }).pipe(
      mergeMap(context => {
        this.setContext(context);

        try {
          const contextUuid = sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.CONTEXT_UUID);

          if (contextUuid !== context.uuid) {
            sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.CONTEXT_UUID, context.uuid);
            sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.CONTEXT_TYPE, context.type);
            this.onSessionContextUuidChange$.next();
          }
        } catch (e) {
          console.error('Incorrect data in session storage');
        }

        return of(context);
      })
    );
  }

  convertDefaultItemToContext$(defaultItem: ContextObject): Observable<ContextObject> {
    switch (defaultItem.type) {
      case CONSTANTS.CONTEXT.ACCOUNT:
        return this.getConvertedAccountContext$(defaultItem);
      case CONSTANTS.CONTEXT.VENUE:
        return this.getConvertedVenueContext$(defaultItem);
      default:
        return observableThrowError(new Error());
    }
  }

  refreshContext$(): Observable<ContextObject> {
    this.permissionsBackendService.invalidateAccountsCache();

    return this.getContext$().pipe(
      first(),
      mergeMap(currentContext => {
        if (currentContext.type === CONSTANTS.CONTEXT.ACCOUNT) {
          this.permissionsBackendService.invalidateVenuesCacheForAccount(currentContext.uuid);
        } else {
          this.permissionsBackendService.invalidateVenuesCacheForAllAccounts();
        }

        return this.switchContext$(currentContext.type, currentContext.uuid);
      })
    );
  }

  getAccountChildrenNameTranslation(singular: boolean): string {
    const language = this.isCorrectLangInLocalStorage() ? localStorage.getItem(CONSTANTS.AUTH.LANGUAGE) : 'en-GB';
    const accountChildrenName = this.localStorage.retrieve(CONSTANTS.LOCAL_STORAGE_KEYS.CHILDREN_CONTEXT) ? this.localStorage.retrieve(CONSTANTS.LOCAL_STORAGE_KEYS.CHILDREN_CONTEXT) : 'venue';
    const translationsArray = this.accountChildrenNameTranslations.find((element) => {
      return element.name === accountChildrenName;
    });
    const translation = translationsArray.languages.find((element) => {
      return element.code === language;
    });
    return singular ? translation.singular : translation.plural;
  }


  isCorrectLangInLocalStorage(): boolean {
    switch (localStorage.getItem(CONSTANTS.AUTH.LANGUAGE)) {
      case AvailableLanguages.britishEnglish:
        return true;
      case AvailableLanguages.spanish:
        return true;
      case AvailableLanguages.german:
        return true;
      case AvailableLanguages.french:
        return true;
      case AvailableLanguages.italian:
        return true;
      default:
        return false;
    }
  }

  private getConvertedVenueContext$(defaultItem: ContextObject): Observable<ContextObject> {
    const convertedContext = { ...defaultItem };

    return this.usersBackendService.getSingleVenue$(convertedContext.uuid).pipe(
      mergeMap(venueResponse =>
        forkJoin([
          this.usersBackendService.getSingleAccount$(venueResponse.accountUuid),
          this.permissionsBackendService.getVenuePermissions$(venueResponse.accountUuid),
        ]).pipe(
          map(([accountResponse, venuePermissionsResponse]) => {
            this.setDefaultTimezone(venueResponse.location.timezone);
            convertedContext.timezone = venueResponse.location.timezone;
            convertedContext.accountName = accountResponse.brandName;
            convertedContext.venueName = venueResponse.brandName;
            convertedContext.isUserVenueAdmin = this.isUserVenueAdmin(convertedContext.uuid, venuePermissionsResponse);

            return convertedContext;
          })
        )
      )
    );
  }

  private getConvertedAccountContext$(defaultItem: ContextObject): Observable<ContextObject> {
    const convertedContext = { ...defaultItem };

    return forkJoin([
      this.usersBackendService.getSingleAccount$(convertedContext.uuid),
      this.permissionsBackendService.getPermissions$(),
    ]).pipe(
      map(([accountResponse, accountPermissionsResponse]) => {
        this.setDefaultTimezone(accountResponse.location.timezone);
        convertedContext.timezone = accountResponse.location.timezone;
        convertedContext.accountName = accountResponse.brandName;
        convertedContext.isUserAccountAdmin = this.isUserAccountAdmin(convertedContext.uuid, accountPermissionsResponse);
        convertedContext.venueType = accountResponse.questionSet.name;

        return convertedContext;
      })
    );
  }

  private isUserAccountAdmin(accountUuid: string, permissionsList: PermissionsEntry[]): boolean {
    return permissionsList.some(
      permissionEntry => permissionEntry.accountUuid === accountUuid && permissionEntry.hasAccountAdminPermissions
    );
  }

  private isUserVenueAdmin(venueUuid: string, permissionsList: VenuePermissionsEntry[]): boolean {
    return permissionsList.some(permissionEntry => permissionEntry.venueUuid === venueUuid && permissionEntry.hasVenueAdminPermissions);
  }

  private canVenueContextSwitchToAccountContext$(
    currentContext: ContextObject,
    newContextType: string,
    accountUuid: string
  ): Observable<boolean> {
    if (currentContext && currentContext.type === CONSTANTS.CONTEXT.VENUE && newContextType === CONSTANTS.CONTEXT.ACCOUNT) {
      return this.permissionsBackendService.getPermissions$().pipe(
        map(permissions => {
          const accountPermissions = find(permissions, { accountUuid });

          return accountPermissions && accountPermissions.permissions.permission_account_use;
        })
      );
    }

    return of(true);
  }

  private updateAccountOnContextChange() {
    this.getContext$().subscribe((data: ContextObject) => {
      if (ContextService.isAccountContext(data)) {
        this.usersBackendService
          .getSingleAccount$(data.uuid)
          .subscribe((response: IFoodbackAccount) => this.accountService.setAccount(response));
      } else {
        this.usersBackendService
          .getSingleVenue$(data.uuid)
          .pipe(mergeMap((response: VenueFromBackend) => this.usersBackendService.getSingleAccount$(response.accountUuid)))
          .subscribe((response: IFoodbackAccount) => this.accountService.setAccount(response));
      }
    });
  }

  private setDefaultTimezone(timezone: string) {
    moment.tz.setDefault(timezone);
  }
}
