import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '../constants';

@Pipe({
  name: 'mapRewardType',
})
export class MapRewardTypePipe implements PipeTransform {
    transform(value: string): string | undefined {
        const foundKey = Object.keys(CONSTANTS.REWARD_TYPE).find(
          key => CONSTANTS.REWARD_TYPE[key as keyof typeof CONSTANTS.REWARD_TYPE] === value
        );
        return foundKey || value;
      }
}