<div>
  <!-- has to be wrapped in div, otherwise an error is thrown -->
  <!--  <canvas baseChart-->
  <!--          *ngIf="chartData"-->
  <!--          [chartType]="'bar'"-->
  <!--          [options]="chartOptions"-->
  <!--          [datasets]="chartData"-->
  <!--          [labels]="chartLabels"-->
  <!--          [colors]="chartColors"-->
  <!--          height="300"></canvas>-->
  <h3 class="score-chart-title">Score Distribution</h3>

  <fusioncharts type="mscombidy2d" width="100%" height="150" [dataSource]="dataSource">
  </fusioncharts>
</div>