import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NotificationService } from '../../../core/providers/notification/notification.service';

@Directive({
  selector: '[appFileDragNDrop]',
})
export class FileDragNDropDirective {
  @Input() allowedExtensions: string[] = ['jpg', 'jpeg', 'png', 'bmp'];
  @Input() allowedFileSizeInMB = 1;
  @Input() fileType = 'sheet';

  @Input() set files(files: File[]) {
    if ((files && this.isFilesValid(files)) || !files) {
      // this.showErrorMessage();
      this.onFilesChanged.emit(null);
    }
  }

  @Input() set isError(value: boolean) {
    if (value) {
      this.resetSettings();
    } else {
      this.showErrorMessage();
    }
  }

  @Output() readonly onFilesChanged: EventEmitter<File[]> = new EventEmitter();
  // @Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background: string;
  @HostBinding('style.border') private borderStyle: string;
  @HostBinding('style.border-color') private borderColor: string;

  private defaultBorderColor = '#ddd';
  private defaultBackgroundColor = '#fff';
  private defaultRequirementsColor = '#555555';
  private onDragOverBorderColor = 'cadetblue';
  private onDragOverBackgroundColor = 'lightgray';
  private defaultBorderStyle = '1px dotted';
  private onDragOverBorderStyle = '2px solid';
  private onErrorColor = '#f05050';
  private onErrorBackgroundColor = '#E261614D';
  private onErrorBorderStyle = '2px solid';

  constructor(private notificationService: NotificationService) {
    if (this.fileType === 'sheet') {
      this.allowedExtensions = ['csv'];
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = this.onDragOverBackgroundColor;
    this.borderColor = this.onDragOverBorderColor;
    this.borderStyle = this.onDragOverBorderStyle;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.resetSettings();
  }

  @HostListener('drop', ['$event'])
  onDrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.resetSettings();
    const files: File[] = evt.dataTransfer.files;
    const valid_files: File[] = files;

    if (this.isFilesValid(valid_files)) {
      this.showErrorMessage();
    } else {
      this.onFilesChanged.emit(valid_files);
    }
  }

  private showErrorMessage(): void {
    this.background = this.onErrorBackgroundColor;
    this.borderColor = this.onErrorColor;
    this.borderStyle = this.onErrorBorderStyle;

    try {
      (
        document.getElementsByClassName(
          'RequirementsContainer'
        )[0] as HTMLElement
      ).style.color = this.onErrorColor;
    } catch (e) {
      console.error('Missing requirements container!');
    }

    this.notificationService.error('File does not meet requirements!');
  }

  private resetSettings(): void {
    this.background = this.defaultBackgroundColor;
    this.borderColor = this.defaultBorderColor;
    this.borderStyle = this.defaultBorderStyle;
    const requirementsContainer: HTMLCollectionOf<Element> =
      document.getElementsByClassName('RequirementsContainer');

    if (requirementsContainer && requirementsContainer.length > 0) {
      (requirementsContainer[0] as HTMLElement).style.color =
        this.defaultRequirementsColor;
    }
  }

  private isFilesValid(files: File[]): boolean {
    for (let i = 0; i < files.length; i++) {
      let isFileValid = false;

      const bytesNumb = 1024;

      if (files[i].size / bytesNumb / bytesNumb > this.allowedFileSizeInMB) {
        return true;
      }
      for (let j = 0; j < this.allowedExtensions.length; j++) {
        const sCurExtension: string = this.allowedExtensions[j];
        const sFileName: string = files[i].name;
        const sFileExtension: string =
          sFileName.split('.')[sFileName.split('.').length - 1];

        if (sFileExtension.toLowerCase() === sCurExtension.toLowerCase()) {
          isFileValid = true;
          break;
        }
      }
      if (!isFileValid) {
        return true;
      }
    }

    return false;
  }
}
