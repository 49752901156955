// VENDORS:
import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeNo from '@angular/common/locales/nb';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import * as FusionCharts from 'fusioncharts';
import { IntercomModule } from 'ng-intercom';
import { NgxWebstorageModule } from 'ngx-webstorage';

// APPLICATION IMPORTS:
import { TemplatePageTitleStrategy } from './app-title-strategy';
import { AppComponent } from './app.component';
import { AppRoutes, AppRoutingModule } from './app.routes';
import { TranslateLoaderFactory } from './app.translations';
import { LoginComponent } from './components/login/login.component';
import { LoginModule } from './components/login/login.module';
import { NoAccessModule } from './components/no-access/no-access.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageNotFoundModule } from './components/page-not-found/page-not-found.module';
import { CoreModule } from './core/core.module';
import { LanguageService } from './core/providers/backend/language.service';
import { FbiInterceptor } from './fbi.interceptor';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import {environment} from "../environments/environment";

registerLocaleData(localeNo);
// eslint-disable-next-line @typescript-eslint/dot-notation
FusionCharts.options['license']({
  key: environment.fusionChartsKey,
  creditLabel: false,
});

@NgModule({
  declarations: [AppComponent, LoginComponent, PageNotFoundComponent],
  providers: [
    {provide: LOCALE_ID, useValue: 'nb'},
    {provide: HTTP_INTERCEPTORS, useClass: FbiInterceptor, multi: true},
    LanguageService,
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
  imports: [
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
      },
    }),
    BrowserModule,
    CoreModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    IntercomModule,
    LayoutModule,
    LoginModule,
    NoAccessModule,
    PageNotFoundModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// todo: LAZY LOAD TRANSLATIONS, SMALLER FILES
