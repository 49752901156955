import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DashboardQueryParams } from '../../../../core/interfaces/dashboard-query-params.interface';
import { IDataCardWidgetScaffold } from '../data-card-widget-scaffold.interface';

@Component({
  selector: 'app-data-card-widget',
  templateUrl: './data-card-widget.component.html',
  styleUrls: ['./data-card-widget.component.scss'],
})
export class DataCardWidgetComponent extends IDataCardWidgetScaffold {
  @Input() isTitleUppercase = true;
  @Input() showIcon = true;
  @Input() isAccessToGoals: boolean;
  @Input() accountContext = false;
  @Input() canRedirectToAnotherPage = true;
  @Input() isScoreDistribution = false;
  @Input() scoresDistributionData;

  @Output() readonly onScrollTrigger = new EventEmitter();

  isVenuesExcluded = false;
  venues: string[] = [];
  venuesQuery = '&d';
  currentValue = 4.2;
  goal = 5.5;
  
  chartConfig = {
    width: '300',
    height: '50',
    type: 'hlineargauge',
    dataFormat: 'json',
    dataSource: {
      chart: {
        theme: 'fusion',
        lowerLimit: '0',
        upperLimit: '6',
        showValue: '1',
        valueFontSize: '14',
        valueFontColor: '#333333',
        chartBottomMargin: '0',
        chartTopMargin: '0',
        showTickMarks: '0',
        showTickValues: '0',
        showBorder: '0',
        useColorForGaugeFill: '1',
        gaugeFillHeight: '60%' // grubość paska
      },
      colorRange: {
        color: [
          {
            minValue: 0,
            maxValue: this.currentValue,
            code: '#28a745' // kolor paska do aktualnej wartości
          },
          {
            minValue: this.currentValue,
            maxValue: 6,
            code: '#ffffff' // biała część do końca
          }
        ]
      },
      pointers: {
        pointer: [
          {
            value: this.currentValue,
            tooltext: `${this.currentValue}`,
            color: '#28a745'
          }
        ]
      },
      trendpoints: {
        point: [
          {
            startValue: this.goal,
            displayValue: 'Goal',
            color: '#000000',
            thickness: '2',
            dashed: '1',
            useMarker: '1',
            markerBorderColor: '#000000',
            markerBgColor: '#000000'
          }
        ]
      }
    }
  };
  constructor(private activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((params: DashboardQueryParams) => {
        this.venues = params.venues
          ? typeof params.venues === 'string'
            ? [params.venues]
            : params.venues
          : [];
        this.isVenuesExcluded = params.isVenuesExcluded === 'true';
        this.venuesQuery =
          this.venues && this.venues.length > 0
            ? `&venues=${this.venues.join('&venues=')}&isVenuesExcluded=${
                params.isVenuesExcluded
              }`
            : '';
      });
  }

  scrollToElement(entry) {
    const isFoodbacksLink = entry.title === "SHARED.SYSTEM.FOODBACKS" ? true : false;
    this.onScrollTrigger.emit(isFoodbacksLink);
}

  getRouterLink(entry: any): any {
    var firstURLPart = entry.mainUrl.url.substring(0, entry.mainUrl.url.indexOf('all-foodbacks') + 13);
    return firstURLPart;
  }

  getParamsForRoute(entry: any): any {
    if (entry.title.includes('EMAIL')) {
      return {hasEmail: true, isVenuesExcluded: this.isVenuesExcluded, venues: this.venues};
    } else if (entry.title.includes('COMMENTS')) {
      return {hasComment: true, isVenuesExcluded: this.isVenuesExcluded, venues: this.venues};    }
    return {isVenuesExcluded: this.isVenuesExcluded, venues: this.venues}; 
  }
}
