import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { UtilsService } from '../../../core/utils/utils.service';
import { CONSTANTS } from '../../constants';
import { DateRange, DynamicDateRange, StaticDateRange, StaticDateRangePeriod } from '../../interfaces/date-range.interface';
import { IDropdownItem } from '../../interfaces/dropdown/dropdown-item.interface';
import { DateGroupPickerValue, DynamicPickerEntry, StaticPickerEntry } from './date-range-picker.interface';
import StartOf = moment.unitOfTime.StartOf;

@Injectable()
export class DateRangePickerService {
  minDateRange: Date = CONSTANTS.DASHBOARD.MIN_DATE_FOR_RANGE;
  readonly dynamicPickerEntries: DynamicPickerEntry[] = [
    {
      name: 'DAY',
      value: DateGroupPickerValue.DAY,
    },
    {
      name: 'WEEK',
      value: DateGroupPickerValue.WEEK,
    },
    {
      name: 'MONTH',
      value: DateGroupPickerValue.MONTH,
    },
    {
      name: 'YEAR',
      value: DateGroupPickerValue.YEAR,
    },
  ];
  readonly staticPickerEntries: StaticPickerEntry[] = [
    {
      name: 'LAST_X_DAYS',
      value: DateGroupPickerValue.STATIC,
      period: StaticDateRangePeriod.DAYS,
      count: 7,
    },
    {
      name: 'LAST_X_DAYS',
      value: DateGroupPickerValue.STATIC,
      period: StaticDateRangePeriod.DAYS,
      count: 14,
    },
    {
      name: 'LAST_X_DAYS',
      value: DateGroupPickerValue.STATIC,
      period: StaticDateRangePeriod.DAYS,
      count: 28,
    },
    {
      name: 'LAST_X_MONTHS',
      value: DateGroupPickerValue.STATIC,
      period: StaticDateRangePeriod.MONTHS,
      count: 12,
    },
  ];
  readonly allTimePickerEntry: StaticPickerEntry = {
    name: 'ALL_TIME',
    value: DateGroupPickerValue.STATIC,
    period: StaticDateRangePeriod.ALL_TIME,
    count: 1,
  };
  private timeFrames: IDropdownItem[] = [];

  constructor(private translateService: TranslateService, private utilsService: UtilsService) {}

  getInitialDateRange(): DynamicDateRange {
    const group: DateGroupPickerValue = DateGroupPickerValue.MONTH;

    return this.getInitialRangeForGroup(group);
  }

  getInitialRangeForGroup(group: DateGroupPickerValue): DynamicDateRange {
    const unit = this.mapGroupToMomentStartOfUnit(group);
    const start = new Date(moment(new Date()).startOf(unit).format().split('T')[0]);
    const end = new Date(moment(new Date()).endOf(unit).format().split('T')[0]);

    // start.setHours(0, 0, 0);
    // eslint-disable-next-line
    // end.setHours(0, 0, 0);

    return {
      start: new Date(start.toISOString().slice(0, -1)),
      end: new Date(end.toISOString().slice(0, -1)),
      groupBy: group,
    };
  }

  transformDateRangeToCustom(dateRange: DateRange, minDate?: Date): DynamicDateRange {
    if (this.assertDateRangeTypeDynamic(dateRange)) {
      return {
        start: dateRange.start,
        end: dateRange.end,
        groupBy: DateGroupPickerValue.CUSTOM,
      };
    }

    return this.convertStaticDateRangeToCustomDynamic(dateRange, minDate);
  }

  subtractOneUnitFromDate(date: Date, group: DateGroupPickerValue): Date {
    return moment(date).subtract(1, this.mapGroupToMomentBaseUnit(group)).toDate();
  }

  getStartOfNextRange(date: Date, group: DateGroupPickerValue, value = 1): Date {
    switch (group) {
      case DateGroupPickerValue.DAY:
        return this.addDays(date, value);
      case DateGroupPickerValue.WEEK:
        return this.addWeeks(date, value);
      case DateGroupPickerValue.MONTH:
        return this.addMonths(date, value);
      case DateGroupPickerValue.YEAR:
        return this.addYear(date, value);
      default:
        return date;
    }
  }

  getEndOfNextRange(date: Date, group: DateGroupPickerValue, value = 1): Date {
    switch (group) {
      case DateGroupPickerValue.DAY:
        return this.addDays(date, value);
      case DateGroupPickerValue.WEEK:
        return this.addWeeks(date, value);
      case DateGroupPickerValue.MONTH:
        const datePom = new Date(date.getFullYear(), date.getMonth(), 1);

        return new Date(datePom.getFullYear(), datePom.getMonth() + 1 + value, 0);
      case DateGroupPickerValue.YEAR:
        return this.addYear(date, value);
      default:
        return date;
    }
  }

  assertDateRangeTypeDynamic(dateRange: DateRange): dateRange is DynamicDateRange {
    if (dateRange && (dateRange as DynamicDateRange).start && (dateRange as DynamicDateRange).end) {
      return !!((dateRange as DynamicDateRange).start && (dateRange as DynamicDateRange).end);
    }

    return null;
  }

  assertDateRangeTypeStatic(dateRange: DateRange): dateRange is StaticDateRange {
    return !!((dateRange as StaticDateRange).count && (dateRange as StaticDateRange).period);
  }

  convertStaticDateRangeToCustomDynamic(dateRange: StaticDateRange, minDate?: Date): DynamicDateRange {
    let start;

    const end = new Date(new Date().toISOString().slice(0, 10) + CONSTANTS.DATE_TIME_VALUES.MIDNIGHT);
    const period = dateRange && dateRange.period ? dateRange.period : StaticDateRangePeriod.DAYS;
    // eslint-disable-next-line no-magic-numbers
    const count = dateRange && dateRange.count ? dateRange.count : 28;

    end.setHours(0, 0, 0);

    if (period === StaticDateRangePeriod.ALL_TIME) {
      start = minDate ?  minDate : this.minDateRange;
    } else if (period === StaticDateRangePeriod.MONTHS) {
      const endCopy = new Date(end);

      start = new Date(endCopy.setMonth(endCopy.getMonth() - count));
    }else {
      const endCopy = new Date(end);

      start = new Date(endCopy.setDate(end.getDate() - count));
    }

    start.setHours(0, 0, 0);

    return {
      start,
      end,
      groupBy: DateGroupPickerValue.CUSTOM,
    };
  }

  getCovertedDateWithTimezone(date: Date) {
    return new Date(moment(date).format(CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE));
  }

  getTimeFrames(): IDropdownItem[] {
    return this.timeFrames;
  }

  saveSelectedTimeFrameInStore(selectedTimeFrame: IDropdownItem) {
    const data = JSON.stringify(selectedTimeFrame);

    if (data) {
      sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_TIME_FRAME_HASH, this.utilsService.getHashCode(data).toString());
      sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_TIME_FRAME, data);
    }
  }

  saveDateRangeInStore(selectedDateRange: any) {
    const data = JSON.stringify(selectedDateRange);

    if (data) {
      sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_DATE_RANGE_HASH, this.utilsService.getHashCode(data).toString());
      sessionStorage.setItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_DATE_RANGE, data);
    }
  }

  getSelectedTimeFrameInStore(): IDropdownItem {
    if (
      !this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_TIME_FRAME,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_TIME_FRAME_HASH
      )
    ) {
      return null;
    }

    return JSON.parse(sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_TIME_FRAME));
  }

  getDateRangeInStore(): DateRange {
    if (
      !this.utilsService.isHashKeyCorrect(
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_DATE_RANGE,
        CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_DATE_RANGE_HASH
      )
    ) {
      return null;
    }

    return JSON.parse(sessionStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECTED_DATE_RANGE));
  }

  prepareTimeFrames() {
    const allTimeFrame: IDropdownItem = this.getTimeFrameDropdownItem(
      this.allTimePickerEntry.value,
      `SHARED.SYSTEM.${this.allTimePickerEntry.name}`,
      this.allTimePickerEntry
    );
    const daysFrame: IDropdownItem = {
      id: StaticDateRangePeriod.DAYS,
      text: this.translateService.instant('SHARED.SYSTEM.DAYS'),
      subItems: this.staticPickerEntries.map((entry: StaticPickerEntry, i) =>
        this.getTimeFrameDropdownItem(
          `${i} - ${entry.value}`,
          `SHARED.SYSTEM.${entry.name}`,
          entry,
          this.getTranslateArgForStaticPickerValue(entry)
        )
      ),
    };
    const timesFrame: IDropdownItem = {
      id: StaticDateRangePeriod.TIMES,
      translationKey: 'SHARED.COMPONENTS.DATE_PICKER.HEADERS.TIMES',
      subItems: this.dynamicPickerEntries.map((entry: DynamicPickerEntry) =>
        this.getTimeFrameDropdownItem(entry.value, `SHARED.SYSTEM.${entry.name}`, entry)
      ),
    };
    const customTimeFrame: IDropdownItem = this.getTimeFrameDropdownItem(
      DateGroupPickerValue.CUSTOM,
      `SHARED.SYSTEM.${DateGroupPickerValue.CUSTOM.toUpperCase()}`,
      {
        name: 'CUSTOM',
        value: DateGroupPickerValue.CUSTOM,
      }
    );

    this.timeFrames = [allTimeFrame, daysFrame, timesFrame, customTimeFrame];
  }

  storeAllFoodbackDateRange(dateFromValue: Moment, dateToValue: Moment) {
    const dateFrom = moment(dateFromValue);
    const dateTo = moment(dateToValue);
    const isSameDate = moment(dateFrom).isSame(dateTo);
    if (isSameDate) {
      return;
    }
    const data =
      dateFrom && dateTo
        ? this.getDateDropdownRangeItemByDateRange(dateFrom, dateTo)
        : this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[0], DateGroupPickerValue.STATIC);

    if (data.dropdownItem) {
      this.saveSelectedTimeFrameInStore(data.dropdownItem);

      if ((dateFrom && dateTo) && !isSameDate) {
        
        this.saveDateRangeInStore({
          start: dateFrom.format(CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE),
          end: dateTo.format(CONSTANTS.DATES_FORMAT.QUERY_PARAM_DATE),
          groupBy: data.dateRangeGroup,
        });
      }
    }
  }

  private addMonths(date, months) {
    const d = date.getDate();

    date.setMonth(date.getMonth() + +months);

    if (date.getDate() !== d) {
      date.setDate(0);
    }

    return date;
  }

  private addWeeks(date, weeks) {
    const numOfDays = 7;

    date.setDate(date.getDate() + numOfDays * weeks);

    return date;
  }

  private addDays(date, days) {
    date.setDate(date.getDate() + days);

    return date;
  }

  private addYear(date, years) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return new Date(year + years, month, day);
  }

  private mapGroupToMomentStartOfUnit(group: DateGroupPickerValue): moment.unitOfTime.StartOf {
    switch (group) {
      case DateGroupPickerValue.YEAR:
        return 'year';
      case DateGroupPickerValue.MONTH:
        return 'month';
      case DateGroupPickerValue.WEEK:
        return 'isoWeek';
      case DateGroupPickerValue.DAY:
        return 'day';
      default:
        return 'month';
    }
  }

  private mapGroupToMomentBaseUnit(group: DateGroupPickerValue): moment.unitOfTime.Base {
    const unit = this.mapGroupToMomentStartOfUnit(group);

    return unit === 'isoWeek' ? 'week' : (unit as moment.unitOfTime.Base);
  }

  private getTranslateArgForStaticPickerValue(staticPickerEntry: StaticPickerEntry) {
    return { [staticPickerEntry.period]: staticPickerEntry.count };
  }

  private getTimeFrameDropdownItem(id: string, translationCode: string, value?: any, interpolateParams?: {}): IDropdownItem {
    return {
      id,
      value,
      interpolateParams,
      translationKey: translationCode,
    };
  }

  private getDateDropdownRangeItemByDateRange(
    dateFromValue: Moment,
    dateToValue: Moment
  ): { dropdownItem: IDropdownItem; dateRangeGroup: DateGroupPickerValue } {
    const dateFrom = moment(dateFromValue);
    const dateTo = moment(dateToValue);
    const diff = moment.duration(dateTo.diff(dateFrom));
    const timeValues = [];
    const dateFromCopy = moment(dateFrom);
    const isFirstDayOfMonth = this.isFirstDayOfPeriod(dateFrom, 'month');
    const isLastDayOfMonth = this.isLastDayOfPeriod(dateTo, 'month');
    const isFirstDayOfWeek = this.isFirstDayOfPeriod(dateFrom, 'isoWeek');
    const isLastDayOfWeek = this.isLastDayOfPeriod(dateTo, 'isoWeek');
    const numberOfMonths = 12;
    const numberOfDays = 6;
    const periodIndex = 2;
    const dayIndex = 1;
    const last7Days = 7;
    const last14Days = 14;
    const last28Days = 28;
    const customIndex = 3;
    const last12Months = 12;

    while (dateTo > dateFromCopy || dateFromCopy.format('M') === dateTo.format('M')) {
      timeValues.push(dateFromCopy.format('YYYY-MM'));
      dateFromCopy.add(1, 'month');
    }
    if (this.timeFrames.length === 0) {
      this.prepareTimeFrames();
    }
    const timeFrameStatus = {
      isYear: timeValues.length === numberOfMonths && isFirstDayOfMonth && isLastDayOfMonth,
      isMonth: timeValues.length === 1 && isFirstDayOfMonth && isLastDayOfMonth,
      isWeek: diff.days() % numberOfDays === 0 && isFirstDayOfWeek && isLastDayOfWeek,
      isDay: Math.floor(moment.duration(dateTo.diff(dateFrom)).asDays()) <= 0,
      isLat7Days: this.isXLastDays(last7Days, dateFrom, dateTo),
      isLast14Days: this.isXLastDays(last14Days, dateFrom, dateTo),
      isLast28Days: this.isXLastDays(last28Days, dateFrom, dateTo),
      isLast12Months: this.isXLastMonths(last12Months, dateFrom, dateTo)
    };

    if (timeFrameStatus.isYear) {
      const index = 3;

      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[periodIndex].subItems[index], DateGroupPickerValue.YEAR);
    }
    if (timeFrameStatus.isMonth) {
      const index = 2;

      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[periodIndex].subItems[index], DateGroupPickerValue.MONTH);
    }
    if (timeFrameStatus.isWeek) {
      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[periodIndex].subItems[1], DateGroupPickerValue.WEEK);
    }
    if (timeFrameStatus.isDay) {
      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[periodIndex].subItems[0], DateGroupPickerValue.DAY);
    }
    if (timeFrameStatus.isLat7Days) {
      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[dayIndex].subItems[0], DateGroupPickerValue.STATIC);
    }
    if (timeFrameStatus.isLast14Days) {
      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[dayIndex].subItems[1], DateGroupPickerValue.STATIC);
    }
    if (timeFrameStatus.isLast28Days) {
      const index = 2;

      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[dayIndex].subItems[index], DateGroupPickerValue.STATIC);
    }
    if (timeFrameStatus.isLast12Months) {
      const index = 3;

      return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[dayIndex].subItems[index], DateGroupPickerValue.STATIC);
    }


    return this.getProperDropdownItemAndDateRangeGroupObj(this.timeFrames[customIndex], DateGroupPickerValue.CUSTOM);
  }

  private getProperDropdownItemAndDateRangeGroupObj(
    dropdownItem,
    dateRangeGroup
  ): { dropdownItem: IDropdownItem; dateRangeGroup: DateGroupPickerValue } {
    return { dropdownItem, dateRangeGroup };
  }

  private isFirstDayOfPeriod(date: Moment, period: StartOf): boolean {
    const startOfMonth = moment(date).startOf(period);

    return Math.floor(moment.duration(date.diff(startOfMonth)).asDays()) <= 0;
  }

  private isLastDayOfPeriod(date: Moment, period: StartOf): boolean {
    const endOfMonth = moment(date).endOf(period);

    return Math.floor(moment.duration(endOfMonth.diff(date)).asDays()) <= 0;
  }

  private isXLastDays(numberOfDays: number, dateFrom: Moment, dateTo: Moment): boolean {
    if (dateFrom.isSame(new Date(), 'day')) {
      return false;
    }

    return Math.ceil(moment.duration(dateTo.diff(dateFrom)).asDays()) === numberOfDays;
  }

  private isXLastMonths(numberOfMonths: number, dateFrom: Moment, dateTo: Moment): boolean {
    if (dateFrom.isSame(new Date(), 'month')) {
      return false;
    }

    if (moment.duration(dateTo.diff(dateFrom)).asMonths() > 12.04) {
      return false;
    }

    return Math.floor(moment.duration(dateTo.diff(dateFrom)).asMonths()) === numberOfMonths;
  }
}
