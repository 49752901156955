import { Injectable } from '@angular/core';
import { toNumber } from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DateGroupPickerValue } from '../../../shared/components/date-range-picker';
import { CONSTANTS } from '../../../shared/constants';
import {
  IVenueScores,
  QuestionDetails,
  QuestionFoodbacksFromBackend,
  QuestionSummary,
  QuestionSummaryFromBackend,
  VenueCategoryQuestionsScores,
  VenueCategoryScoresDistribution,
  VenueCategorySummary,
  VenueCategorySummaryFromBackend,
  VenueScore,
  VenueScoreFromBackend,
  VenueScoresFromBackend,
  VenueStatistics,
} from '../../../shared/interfaces/analytics.interface';
import { DynamicDateRange } from '../../../shared/interfaces/date-range.interface';
import { AnalyticsProvider, AnalyticsRequestPayload } from '../../interfaces/analytics-payload.interface';
import { AccountService } from '../account/account.service';
import { ApiService } from '../api/api.service';
import { CategoriesBackendService } from './categories-backend.service';
import { FoodbackAccount } from '../../classes/account/account.class';
import { PayloadForFoodbacks } from '../../interfaces/payload-for-foodbacks.interface';
import { UtilsService } from '../../utils/utils.service';

@Injectable()
export class SharedAnalyticsBackendService implements AnalyticsProvider {
  protected analyticsPath: string;

  constructor(
    private accountService: AccountService,
    protected apiService: ApiService,
    protected categoriesBackendService: CategoriesBackendService,
    protected utilsService: UtilsService
  ) {}

  getStatistics$(uuid: string, dateRange: DynamicDateRange, selectedGroupsUuids?: string[]): Observable<VenueStatistics> {
    return this.makeAnalyticsRequest$<VenueStatistics>(CONSTANTS.ENDPOINTS.STATISTICS, uuid, dateRange, selectedGroupsUuids);
  }

  getScores$(uuid: string, dateRange: DynamicDateRange, selectedGroupsUuids?: string[]): Observable<IVenueScores> {
    return this.makeAnalyticsRequest$(CONSTANTS.ENDPOINTS.SCORES, uuid, dateRange, selectedGroupsUuids).pipe(
      map((response: VenueScoresFromBackend) => this.mapScoresFromBackendToScores(response))
    );
  }

  getDayByDayScoresForStatement$(
    uuid: string,
    dateRange: DynamicDateRange,
    statementUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<any> {
    return this.accountService.getAccount$().pipe(
      first(),
      switchMap((data: FoodbackAccount) =>
        data.hasAccessTo().dailyPerformanceOverview
          ? this.makeAnalyticsRequest$(`statements/${statementUuid}/scores/distributions/dayOfWeek`, uuid, dateRange, selectedGroupsUuids)
          : EMPTY
      )
    );
  }

  getDayByDayScoresForCategory$(
    uuid: string,
    dateRange: DynamicDateRange,
    categoryUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<any> {
    return this.accountService.getAccount$().pipe(
      first(),
      switchMap((data: FoodbackAccount) =>
        data.hasAccessTo().dailyPerformanceOverview
          ? this.makeAnalyticsRequest$(`category/${categoryUuid}/scores/distributions/dayOfWeek`, uuid, dateRange, selectedGroupsUuids)
          : EMPTY
      )
    );
  }

  getHourByHourScoresForStatement$(
    uuid: string,
    dateRange: DynamicDateRange,
    statementUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<any> {
    return this.accountService.getAccount$().pipe(
      first(),
      switchMap((data: FoodbackAccount) =>
        data.hasAccessTo().hourlyPerformanceOverview
          ? this.makeAnalyticsRequest$(`statements/${statementUuid}/scores/distributions/hourRanges`, uuid, dateRange, selectedGroupsUuids)
          : EMPTY
      )
    );
  }

  getHourByHourScoresForCategory$(
    uuid: string,
    dateRange: DynamicDateRange,
    categoryUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<any> {
    return this.accountService.getAccount$().pipe(
      first(),
      switchMap((data: FoodbackAccount) =>
        data.hasAccessTo().hourlyPerformanceOverview
          ? this.makeAnalyticsRequest$(`category/${categoryUuid}/scores/distributions/hourRanges`, uuid, dateRange, selectedGroupsUuids)
          : EMPTY
      )
    );
  }

  getCategoryScores$(
    uuid: string,
    dateRange: DynamicDateRange,
    categoryUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<IVenueScores> {
    return this.makeAnalyticsRequest$(`category/${categoryUuid}/scores`, uuid, dateRange, selectedGroupsUuids).pipe(
      map((response: VenueScoresFromBackend) => this.mapScoresFromBackendToScores(response))
    );
  }

  getOverallExperienceScores$(
    uuid: string,
    contextType: string,
    dateRange: DynamicDateRange,
    selectedGroupsUuids?: string[]
  ): Observable<IVenueScores> {
    return this.categoriesBackendService
      .getOverallExperienceDetails(uuid, contextType, selectedGroupsUuids)
      .pipe(
        mergeMap(overallExperience =>
          overallExperience ? this.getCategoryScores$(uuid, dateRange, overallExperience.uuid, selectedGroupsUuids) : EMPTY
        )
      );
  }

  getDayByDayOverallExperienceScores$(
    uuid: string,
    contextType: string,
    dateRange: DynamicDateRange,
    selectedGroupsUuids?: string[]
  ): Observable<any> {
    return this.categoriesBackendService
      .getOverallExperienceDetails(uuid, contextType, selectedGroupsUuids)
      .pipe(
        mergeMap(overallExperience =>
          overallExperience ? this.getDayByDayScoresForCategory$(uuid, dateRange, overallExperience.uuid, selectedGroupsUuids) : EMPTY
        )
      );
  }

  getHourByHourOverallExperienceScores$(
    uuid: string,
    contextType: string,
    dateRange: DynamicDateRange,
    selectedGroupsUuids?: string[]
  ): Observable<any> {
    return this.categoriesBackendService
      .getOverallExperienceDetails(uuid, contextType, selectedGroupsUuids)
      .pipe(
        mergeMap(overallExperience =>
          overallExperience ? this.getHourByHourScoresForCategory$(uuid, dateRange, overallExperience.uuid, selectedGroupsUuids) : EMPTY
        )
      );
  }

  getCategorySummary$(
    uuid: string,
    dateRange: DynamicDateRange,
    categoryUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<VenueCategorySummary> {
    return this.makeAnalyticsRequest$(
      `${CONSTANTS.ENDPOINTS.CATEGORY}/${categoryUuid}/${CONSTANTS.ENDPOINTS.SUMMARY}`,
      uuid,
      dateRange,
      selectedGroupsUuids
    ).pipe(
      map((response: VenueCategorySummaryFromBackend) =>
        Object.keys(response).reduce((reduced, key) => {
          reduced[key] = {
            activeStatementsCount: response[key].activeStatementsCount,
            foodbacksAverageScore: toNumber(response[key].foodbacksAverageScore),
            foodbacksCount: response[key].foodbacksCount,
            emailsCount: response[key].emailsCount,
            commentsCount: response[key].commentsCount,
            totalFoodbacksCount: response[key].totalFoodbacksCount,
          };

          return reduced;
        }, {} as VenueCategorySummary)
      )
    );
  }

  getCategoryQuestionsScores$(
    uuid: string,
    dateRange: DynamicDateRange,
    categoryUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<VenueCategoryQuestionsScores> {
    return this.makeAnalyticsRequest$(
      `${CONSTANTS.ENDPOINTS.CATEGORY}/${categoryUuid}/${CONSTANTS.ENDPOINTS.QUESTIONS}`,
      uuid,
      dateRange,
      selectedGroupsUuids
    );
  }

  getCategoryScoresDistribution$(
    uuid: string,
    dateRange: DynamicDateRange,
    categoryUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<VenueCategoryScoresDistribution> {
    const endpoint = `${CONSTANTS.ENDPOINTS.CATEGORY}/${categoryUuid}/${CONSTANTS.ENDPOINTS.DISTRIBUTIONS}/${CONSTANTS.ENDPOINTS.SCORES}`;

    return this.makeAnalyticsRequest$(endpoint, uuid, dateRange, selectedGroupsUuids);
  }

  getQuestionSummary$(
    uuid: string,
    dateRange: DynamicDateRange,
    questionUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<QuestionSummary> {
    const endpoint = `${CONSTANTS.ENDPOINTS.STATEMENTS}/${questionUuid}/${CONSTANTS.ENDPOINTS.SUMMARY}`;

    return this.makeAnalyticsRequest$(endpoint, uuid, dateRange, selectedGroupsUuids).pipe(
      map((response: QuestionSummaryFromBackend) =>
        Object.keys(response).reduce((reduced, key) => {
          reduced[key] = {
            foodbacksAverageScore: toNumber(response[key].foodbacksAverageScore),
            foodbacksCount: response[key].foodbacksCount,
            commentsCount: response[key].commentsCount,
          };

          return reduced;
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        }, {} as QuestionSummary)
      )
    );
  }

  getQuestionScores$(
    uuid: string,
    dateRange: DynamicDateRange,
    questionUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<IVenueScores> {
    const endpoint = `${CONSTANTS.ENDPOINTS.STATEMENTS}/${questionUuid}/${CONSTANTS.ENDPOINTS.SCORES}`;

    return this.makeAnalyticsRequest$(endpoint, uuid, dateRange, selectedGroupsUuids).pipe(
      map((response: VenueScoresFromBackend) => this.mapScoresFromBackendToScores(response))
    );
  }

  getFoodbackFirstDate$(uuid: string): Observable<{ firstFoodbackDate: string }> {
    const url = `${this.analyticsPath}/${uuid}/${CONSTANTS.ENDPOINTS.FIRST_FOODBACK_DATE}`;

    return this.apiService.get$(url).pipe(map(response => response.content));
  }

  getFoodbackLastDate$(uuid: string): Observable<{ lastFoodbackDate: any }> {
    const url = `${this.analyticsPath}/${uuid}/last-foodbacks-venues-dates`;

    return this.apiService.get$(url).pipe(map(response => response.content.dates));
  }


  getFoodbacksWithQuestion$(
    uuid: string,
    payload: PayloadForFoodbacks,
    questionUuid: string,
    selectedGroupsUuids?: string[]
  ): Observable<QuestionFoodbacksFromBackend> {
    const url = `${this.analyticsPath}/${uuid}/${CONSTANTS.ENDPOINTS.STATEMENTS}/${questionUuid}/${CONSTANTS.ENDPOINTS.FOODBACKS}`;

    return this.apiService.post$(url, payload).pipe(map(response => response.content));
  }

  getQuestionDetails$(questionUuid: string): Observable<QuestionDetails> {
    return this.apiService
      .get$(`${environment.apiBaseUrl.handbook}/surveys/${CONSTANTS.ENDPOINTS.STATEMENTS}/${questionUuid}`)
      .pipe(map(response => response.content));
  }

  protected makeAnalyticsRequest$<T>(
    endpoint: string,
    uuid: string,
    dateRange: DynamicDateRange,
    selectedGroupsUuids?: string[]
  ): Observable<T> {
    return this.apiService
      .get$(`${this.analyticsPath}/${uuid}/${endpoint}`, {
        params: this.getRequestParams(dateRange, selectedGroupsUuids),
      })
      .pipe(map(response => response.content));
  }

  protected getRequestParams(dateRange: DynamicDateRange, selectedGroupsUuids: string[] = []): AnalyticsRequestPayload {
    return {
      dateFrom: this.utilsService.prepareDateForRequest(dateRange.start),
      dateTo: this.utilsService.prepareDateForRequest(dateRange.end),
      precision: DateGroupPickerValue.CUSTOM,
      'venueGroups[]': selectedGroupsUuids,
    };
  }

  private mapScoresFromBackendToScores(scores: VenueScoresFromBackend): IVenueScores {
    return {
      currentCount: scores.currentCount.slice(),
      currentScores: scores.currentScores.map(this.mapScoreEntryFromBackendToScore),
      previousScores: scores.previousScores.map(this.mapScoreEntryFromBackendToScore),
    };
  }

  private mapScoreEntryFromBackendToScore(score: VenueScoreFromBackend): VenueScore {
    return {
      date: score.date,
      value: toNumber(score.value),
    };
  }
}
